import ApiService from "@/core/services/api.service";

//Actions
export const STORE_DEVICE_TOKEN = "storeDeviceToken";
export const REMOVE_DEVICE_TOKEN = "removeDeviceToken";

//Mutations
export const SET_LOADING = "setLoadingSharedBase";

const state = {
  isLoadingSharedNotify: false
};

const getters = {
  isLoadingSharedNotify() {
    return state.isLoadingSharedNotify;
  }
};

const actions = {
  [STORE_DEVICE_TOKEN](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("store-token", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [REMOVE_DEVICE_TOKEN](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("remove-token", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedNotify = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
