import ApiService from "@/core/services/api.service";
import {
  SET_LOADING_TEMPERATURES,
  UPDATE_KITCHEN_EQUIPMENT_TEMPERATURE
} from "../../kitchen-equipments/store/kitchen-equipments.module";
import { FETCH_SUPPLIERS_BASIC } from "../../../core/services/store/shared_supplier.module";

//Action STOCK BASICS
export const FETCH_ITEMS_BY_STORE_AND_SUPPLIER_RECEIVING =
  "fetchItemsByStoreAndSupplierReceiving";
export const FETCH_ITEMS_BY_STORE_AND_SUPPLIER_RETURNING =
  "fetchItemsByStoreAndSupplierReturning";
export const FETCH_ITEMS_BY_STORE_SCRAPPING = "fetchItemsByStoreScrapping";
export const FETCH_ITEMS_BY_STORE_PRODUCTION = "fetchItemsByStoreProduction";
export const FETCH_ITEMS_BY_CLIENT_WHOLESALE = "fetchItemsByClientWholesale";
export const FETCH_ITEMS_BY_CLIENT_WHOLESALE_RETURNING =
  "fetchItemsByClientWholesaleReturning";
//Actions STOCK ACTIONS
export const FETCH_STOCK_ACTIONS = "fetchStockActions";
export const FETCH_STOCK_ACTION = "fetchStockAction";
export const SAVE_STOCK_ACTION = "saveStockAction";
export const FETCH_STOCK_ACTION_ITEMS = "fetchStockActionItems";

export const GET_INCOMING_CONTROL_XML = "getIncomingControlXML";
export const GET_LATEST_ITEM_PRICE_XML = "getLatestItemPriceXml";
export const GET_STOCK_SCRAPPING_PROTOCOL_XLS = "getStockScrappingProtocolXls";

export const UPDATE_STOCK_ACTION_DOC = "updateStockActionDoc";
export const UPDATE_STOCK_DOC_DATA = "updateStockDocData";
export const UPDATE_STOCK_ACTION_CUSTOMS_DOC = "updateStockActionCustomsDoc";
//Actions STOCK AUDITS
export const FETCH_STOCK_AUDITS = "fetchStockAudits";
export const FETCH_STOCK_AUDIT = "fetchStockAudit";
export const FETCH_AUDITABLE_ITEMS_BY_STORE = "fetchAuditableItemsByStore";
export const FETCH_REQUESTABLE_ITEMS_BY_STORE = "fetchRequestableItemsByStore";
export const FETCH_LAST_AUDIT_BY_STORE = "fetchLastAuditByStore";
export const SAVE_STOCK_AUDIT = "saveStockAudit";
export const UPDATE_STOCK_AUDIT = "updateStockAudit";
export const UPDATE_STOCK_AUDIT_LOTS = "updateStockAuditLots";
//Stock AverageCosts
export const FETCH_STOCK_AVG_COSTS = "fetchStockAvgCosts";
export const APPLY_SINGLE_STOCK_ACTION_AVG_COST = "applySingleStockAvgCost";
//StockPurchaseOrders
export const FETCH_STOCK_PURCHASE_ORDER_REQUESTED =
  "fetchStockPurchaseOrderRequested";
export const FETCH_STOCK_PURCHASE_ORDER_PREPARE =
  "fetchStockPurchaseOrderPrepare";
export const FETCH_STOCK_PURCHASE_ORDERS = "fetchStockPurchaseOrders";
export const FETCH_STOCK_PURCHASE_ORDERS_BY_STATUS =
  "fetchStockPurchaseOrdersByStatus";
export const FETCH_STOCK_PURCHASE_ORDERS_RECEIVING =
  "fetchStockPurchaseOrdersReceiving";
export const FETCH_STOCK_PURCHASE_ORDER_RECEIVING =
  "fetchStockPurchaseOrderReceiving";
export const FETCH_STOCK_PURCHASE_ORDERS_DISCREPANCY =
  "fetchStockPurchaseOrdersDiscrepancy";
export const FETCH_STOCK_PURCHASE_ORDER_DISCREPANCY =
  "fetchStockPurchaseOrderDiscrepancy";
export const STOCK_PURCHASE_ORDER_CREATE = "stockPurchaseOrderCreate";
export const SAVE_STOCK_PURCHASE_ORDER_ITEMS = "saveStockPurchaseOrderItems";
export const READY_TO_SHIP_STOCK_PURCHASE_ORDER =
  "readyToShipStockPurchaseOrder";
export const BACK_TO_PROCESSING_STOCK_PURCHASE_ORDER =
  "backToProcessingStockPurchaseOrder";
export const REJECT_STOCK_PURCHASE_ORDER = "rejectStockPurchaseOrder";
export const REJECT_SHIPPED_STOCK_PURCHASE_ORDER =
  "rejectShippedStockPurchaseOrder";
export const CANCEL_STOCK_PURCHASE_ORDER = "cancelStockPurchaseOrder";
export const SHIP_STOCK_PURCHASE_ORDER = "shipStockPurchaseOrder";
export const RECEIVE_STOCK_PURCHASE_ORDER = "receiveStockPurchaseOrder";
export const SOLVE_DISCREPANCY_STOCK_PURCHASE_ORDER =
  "solveDiscrepancyStockPurchaseOrder";
export const EXPORT_STOCK_REDIRECTING_XLS = "exportStockRedirectingXls";
export const EXPORT_ANALYTIC_REVERSE_PAY_SHEET_XLS =
  "exportAnalyticReversePaySheetXls";
export const GET_INVENTORY_LOG_DETAILED_XML = "getInventoryLogDetailedXml";
export const PREPARE_STOCK_XLS = "prepareStockXLS";
export const ADJUST_SUPPLIER_STOCK_RECEIVED = "adjustSupplierStockReceived";
export const ADJUST_WHOLESALE = "adjustWholesale";
export const ADJUST_PRODUCTION = "adjustProduction";
export const FETCH_STOCK_CONSUMPTIONS = "fetchStockConsumptions";

//Clients
export const FETCH_CLIENTS = "fetchClients";
export const FETCH_CLIENT = "fetchClient";
export const SAVE_CLIENT = "saveClient";
export const UPDATE_CLIENT = "updateClient";
export const DELETE_CLIENT = "deleteClient";
export const FETCH_CLIENT_STORES = "fetchClientStores";
export const FETCH_CLIENT_STORE = "fetchClientStore";
export const SAVE_CLIENT_STORE = "saveClientStore";
export const UPDATE_CLIENT_STORE = "updateClientStore";
export const DELETE_CLIENT_STORE = "deleteClientStore";
export const FETCH_CLIENTS_WITH_STORES = "fetchClientsWithStores";

//Invoices

export const FETCH_ECHO_INVOICES = "fetchEchoInvoices";
export const FETCH_STOCK_INVOICES = "fetchStockInvoices";
export const FETCH_STOCK_INVOICE = "fetchStockInvoice";
export const ADD_ITEMS_TO_INVOICE = "addItemsToInvoice";
export const CREATE_NEW_INVOICE = "createNewInvoice";
export const UPDATE_RECIPIENT_DATA = "updateRecipientData";
export const UPDATE_RECIPIENT_ID = "updateRecipientId";
export const UPDATE_ISSUER_DATA = "updateIssuerData";
export const REGISTER_INVOICE = "registerInvoice";
export const UNREGISTER_INVOICE = "unregisterInvoice";
export const REMOVE_DOCUMENT_FROM_INVOICE = "removeDocumentFromInvoice";

export const UPDATE_STOCK_ACTION_TRANSPORT_FORWARDER_ID =
  "updateStockActionTransportForwarderId";
export const UPDATE_STOCK_ACTION_ADDON_CONTRACTOR_ID =
  "updateStockActionAddonContractorId";

//Reference summary
export const FETCH_REFERENCE_SUMMARY = "fetchReferenceSummary";
export const EXPORT_STOCK_REFERENCE_SUMMARY_XLS =
  "exportStockReferenceSummaryXls";
//Expenses
export const FETCH_EXPENSES = "fetchExpenses";
export const EXPORT_EXPENSES_XLS = "exportExpensesXls";

export const GET_ISSUED_DOCUMENTS_XLS = "getStoreIssuedDocumentsXls";
export const GET_ISSUED_DOCUMENTS_FILES = "getIssuedDocumentsFiles";

export const FETCH_STOCK_PURCHASE_ORDER_PRODUCTION =
  "fetchStockPurchaseOrderProduction";
export const STOCK_PURCHASE_ORDER_PRODUCTION_XLS =
  "stockPurchaseOrderProductionXls";

export const FETCH_PROCESSING_ORDER_ITEMS = "fetchProcessingOrderItems";
export const FETCH_PROCESSING_ORDER_ITEMS_XLS = "fetchProcessingOrderItemsXls";

export const STOCK_WHOLESALE_INVOICE_PDF = "stockWholesaleInvoicePdf";

export const UPDATE_WHOLESALE_ITEM = "updateWholesaleItem";
export const UPDATE_ECHO_AJUR = "updateEchoAjur";
export const UPDATE_AJUR = "updateAjur";

export const FETCH_MIXER = "fetchMixer";
export const SAVE_STOCK_ACTION_FILTERS = "saveStockActionFilters";

//Credit Debit
export const FETCH_CREDIT_DEBIT_ACTIONS = "fetchCreditDebitActions";
export const FETCH_CREDIT_DEBIT_STOCK_ACTION = "fetchCreditDebitStockAction";
export const CREATE_CREDIT_DEBIT_STOCK_ACTION = "createCreditDebitStockAction";
export const SEND_ECHO_INVOICES_TO_AJUR = "sendEchoInvoicesToAjur";
export const UPDATE_ECHO_INVOICE = "updateEchoInvoice";
export const STOCK_WHOLESALE_OVERALL_EXCEL = "stockWholesaleOverallExcel";
export const UPDATE_INVOICE = "updateInvoice";
export const FETCH_CLIENT_BASIC = "fetchClientBasic";
export const FETCH_STOCK_CONSUMPTIONS_AJUR_NUMBER =
  "fetchStockConsumptionsAjurNumber";
export const FETCH_STOCK_CONSUMPTIONS_AJUR_NUMBER_DETAILS =
  "fetchStockConsumptionsAjurNumberDetails";
export const ECHO_INVOICES_DOWNLOAD_EXCEL = "echoInvoicesDownloadExcel";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingStock";
export const SET_STOCK_ACTION_FILTERS = "setStockActionFilters";

const state = {
  errors: [],
  isLoadingStock: false,
  stockActionFilters: null
};

const getters = {
  isLoadingStock() {
    return state.isLoadingStock;
  }
};

const actions = {
  [FETCH_STOCK_ACTIONS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-actions" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_ACTION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-actions", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_ACTION_ITEMS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-action-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_INCOMING_CONTROL_XML](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("receiving-control-xml" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_LATEST_ITEM_PRICE_XML](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("get-latest-items-price-xml" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [EXPORT_STOCK_REDIRECTING_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("export-stock-redirecting-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [EXPORT_ANALYTIC_REVERSE_PAY_SHEET_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("stock-analytic-reverse-pay-sheet-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_INVENTORY_LOG_DETAILED_XML](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("stock-inventory-log-detailed-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_STOCK_SCRAPPING_PROTOCOL_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("stock-scrapping-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [PREPARE_STOCK_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("prepare-stock-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ITEMS_BY_STORE_AND_SUPPLIER_RECEIVING](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-receiving-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ITEMS_BY_STORE_AND_SUPPLIER_RETURNING](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-returning-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ITEMS_BY_STORE_SCRAPPING](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-scrapping-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ITEMS_BY_STORE_PRODUCTION](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-production-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ITEMS_BY_CLIENT_WHOLESALE](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-wholesale-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ITEMS_BY_CLIENT_WHOLESALE_RETURNING](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-wholesale-items-returning" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_STOCK_ACTION](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("stock-actions", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STOCK_ACTION_DOC](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-stock-action-doc", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STOCK_DOC_DATA](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-stock-doc-data", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STOCK_ACTION_CUSTOMS_DOC](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-stock-action-customs-doc", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_AUDITS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-audits" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_AUDIT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-audits", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_AUDITABLE_ITEMS_BY_STORE](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-auditable-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_REQUESTABLE_ITEMS_BY_STORE](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-requestable-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_LAST_AUDIT_BY_STORE](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stock-last-audit" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_STOCK_AUDIT](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("stock-audits", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STOCK_AUDIT](context, params) {
    console.log(params);
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("stock-audits", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STOCK_AUDIT_LOTS](context, params) {
    console.log(params);
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-stock-audit-lots", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_AVG_COSTS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-items-last-average-cost" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [APPLY_SINGLE_STOCK_ACTION_AVG_COST](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("apply-single-stock-action-avg-cost", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDER_REQUESTED](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-stock-purchase-order-requested", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDER_RECEIVING](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-stock-purchase-order-receiving", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDER_DISCREPANCY](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-stock-purchase-order-discrepancy", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDER_PREPARE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-stock-purchase-order-prepare", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDERS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-purchase-orders" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDERS_BY_STATUS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-stock-purchase-orders-by-status" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDERS_RECEIVING](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-stock-purchase-orders-receiving" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDERS_DISCREPANCY](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-stock-purchase-orders-discrepancy" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [STOCK_PURCHASE_ORDER_CREATE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("stock-purchase-orders", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_STOCK_PURCHASE_ORDER_ITEMS](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("stock-purchase-order-items", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [READY_TO_SHIP_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("ready-to-ship-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [BACK_TO_PROCESSING_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("back-to-processing-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [REJECT_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("reject-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [CANCEL_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("cancel-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [REJECT_SHIPPED_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("reject-shipped-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SHIP_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("ship-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [RECEIVE_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("receive-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SOLVE_DISCREPANCY_STOCK_PURCHASE_ORDER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("solve-discrepancy-stock-purchase-order", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ADJUST_SUPPLIER_STOCK_RECEIVED](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("adjust-supplier-stock-received", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ADJUST_WHOLESALE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("adjust-wholesale", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ADJUST_PRODUCTION](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("adjust-production", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_CLIENTS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-wholesale-clients" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_CLIENTS_WITH_STORES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("clients-get-all-with-stores" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_CLIENT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-wholesale-clients", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_CLIENT](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("stock-wholesale-clients", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_CLIENT](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("stock-wholesale-clients", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_CLIENT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("stock-wholesale-clients", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_CLIENT_STORES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-wholesale-client-stores" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_CLIENT_STORE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-wholesale-client-stores", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_CLIENT_STORE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("stock-wholesale-client-stores", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_CLIENT_STORE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("stock-wholesale-client-stores", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_CLIENT_STORE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("stock-wholesale-client-stores", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_REFERENCE_SUMMARY](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-reference-summary-list" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [EXPORT_STOCK_REFERENCE_SUMMARY_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("export-stock-reference-summary-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_EXPENSES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-expense-list" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [EXPORT_EXPENSES_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("export-expense-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ISSUED_DOCUMENTS_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("export-store-issued-documents-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ISSUED_DOCUMENTS_FILES](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("get-exported-issued-documents")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_PURCHASE_ORDER_PRODUCTION](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-orders-production" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [STOCK_PURCHASE_ORDER_PRODUCTION_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("stock-orders-production-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_PROCESSING_ORDER_ITEMS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-processing-order-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_PROCESSING_ORDER_ITEMS_XLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getXML("stock-processing-order-items-xls" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ECHO_INVOICES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("echo-invoices" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_INVOICES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-invoices" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_INVOICE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-invoices", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ADD_ITEMS_TO_INVOICE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("add-items-to-invoice", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [CREATE_NEW_INVOICE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("create-new-invoice", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_RECIPIENT_DATA](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-invoice-recipient-data", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_RECIPIENT_ID](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("update-invoice-recipient-id", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ISSUER_DATA](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-invoice-issuer-data", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [REGISTER_INVOICE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("register-invoice", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UNREGISTER_INVOICE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("unregister-invoice", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [REMOVE_DOCUMENT_FROM_INVOICE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("remove-document-from-invoice", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_WHOLESALE_ITEM](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("update-wholesale-item", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [STOCK_WHOLESALE_INVOICE_PDF](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getPDF("invoice-wholesale-pdf" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STOCK_ACTION_TRANSPORT_FORWARDER_ID](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-stock-action-transport-forwarder-id", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STOCK_ACTION_ADDON_CONTRACTOR_ID](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-stock-action-addon-contractor-id", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ECHO_AJUR](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("update-echo-ajur-data", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_AJUR](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("update-ajur-data", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_CONSUMPTIONS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-consumptions" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SEND_ECHO_INVOICES_TO_AJUR](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("send-echo-invoices-to-ajur")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ECHO_INVOICE](context, params) {
    context.commit(SET_LOADING_TEMPERATURES, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("update-echo-invoice-data", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_TEMPERATURES, false);
        });
    });
  },

  [FETCH_CREDIT_DEBIT_ACTIONS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-credit-debit-actions" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [FETCH_CREDIT_DEBIT_STOCK_ACTION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stock-credit-debit-actions", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [CREATE_CREDIT_DEBIT_STOCK_ACTION](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("stock-credit-debit-actions", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_MIXER](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("get-mixer" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [STOCK_WHOLESALE_OVERALL_EXCEL](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.postXML("export-stock-wholesale-overall", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_STOCK_ACTION_FILTERS]({ commit }, value) {
    commit(SET_STOCK_ACTION_FILTERS, value);
  },
  [UPDATE_INVOICE](context, params) {
    context.commit(SET_LOADING_TEMPERATURES, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("update-additional-service", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_TEMPERATURES, false);
        });
    });
  },
  [FETCH_CLIENT_BASIC](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("clients-get-all-basic" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_CONSUMPTIONS_AJUR_NUMBER](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-consumption-by-ajur-number" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STOCK_CONSUMPTIONS_AJUR_NUMBER_DETAILS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-consumption-ajur-number-details" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ECHO_INVOICES_DOWNLOAD_EXCEL](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.postXML("export-echo-invoices", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingStock = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingStock = value;
  },
  [SET_STOCK_ACTION_FILTERS](state, value) {
    state.stockActionFilters = { ...value };
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
