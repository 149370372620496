import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_KITCHEN_EQUIPMENTS = "fetchKitchenEquipments";
export const SAVE_KITCHEN_EQUIPMENT = "saveKitchenEquipment";
export const UPDATE_KITCHEN_EQUIPMENT = "updateKitchenEquipment";
export const DELETE_KITCHEN_EQUIPMENT = "deleteKitchenEquipment";

export const GET_KITCHEN_EQUIPMENT_TEMPERATURE_PDF =
  "getKitchenEquipmentTemperaturesPDF";
export const FETCH_KITCHEN_EQUIPMENTS_TEMPERATURES =
  "fetchKitchenEquipmentTemperatures";
export const GET_KITCHEN_EQUIPMENT_NAME = "getKitchenEquipmentName";
export const SAVE_KITCHEN_EQUIPMENT_TEMPERATURE =
  "saveKitchenEquipmentTemperature";
export const UPDATE_KITCHEN_EQUIPMENT_TEMPERATURE =
  "updateKitchenEquipmentTemperature";
export const DELETE_KITCHEN_EQUIPMENT_TEMPERATURE =
  "deleteKitchenEquipmentTemperature";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingKitchenEquipments";
export const SET_LOADING_TEMPERATURES =
  "setLoadingKitchenEquipmentTemperatures";
export const SET_KITCHEN_EQUIPMENTS = "setKitchenEquipments";
export const SET_KITCHEN_EQUIPMENT_TEMPERATURES =
  "setKitchenEquipmentTemperatures";

const state = {
  errors: [],
  kitchenEquipments: [],
  temperatures: [],
  isLoadingKitchenEquipments: false,
  isLoadingKitchenEquipmentTemperatures: false
};

const getters = {
  isLoadingKitchenEquipments() {
    return state.isLoadingKitchenEquipments;
  },
  getKitchenEquipments() {
    return state.kitchenEquipments;
  },
  isLoadingKitchenEquipmentTemperatures() {
    return state.isLoadingKitchenEquipmentTemperatures;
  },
  getKitchenEquipmentTemperatures() {
    return state.temperatures;
  }
};

const actions = {
  [FETCH_KITCHEN_EQUIPMENTS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("kitchen-equipments" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_KITCHEN_EQUIPMENT](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("kitchen-equipments", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_KITCHEN_EQUIPMENT](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("kitchen-equipments", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_KITCHEN_EQUIPMENT](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.delete("kitchen-equipments", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_KITCHEN_EQUIPMENTS_TEMPERATURES](context, apiParams = "") {
    context.commit(SET_LOADING_TEMPERATURES, true);

    return new Promise((resolve, reject) => {
      ApiService.get("kitchen-equipments-temperatures" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_TEMPERATURES, false);
        });
    });
  },
  [SAVE_KITCHEN_EQUIPMENT_TEMPERATURE](context, payload) {
    context.commit(SET_LOADING_TEMPERATURES, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("kitchen-equipments-temperatures", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_TEMPERATURES, false);
        });
    });
  },
  [UPDATE_KITCHEN_EQUIPMENT_TEMPERATURE](context, params) {
    context.commit(SET_LOADING_TEMPERATURES, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("kitchen-equipments-temperatures", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_TEMPERATURES, false);
        });
    });
  },
  [DELETE_KITCHEN_EQUIPMENT_TEMPERATURE](context, payload) {
    context.commit(SET_LOADING_TEMPERATURES, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.delete("kitchen-equipments-temperatures", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_TEMPERATURES, false);
        });
    });
  },
  [GET_KITCHEN_EQUIPMENT_NAME](context, id) {
    context.commit(SET_LOADING_TEMPERATURES, true);
    return new Promise((resolve, reject) => {
      ApiService.post(`get-kitchen-equipments-name/${id}`, id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_TEMPERATURES, false);
        });
    });
  },
  [GET_KITCHEN_EQUIPMENT_TEMPERATURE_PDF](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getPDF("kitchen-equipments-create-pdf" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingKitchenEquipments = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingKitchenEquipments = value;
  },
  [SET_KITCHEN_EQUIPMENTS](state, data) {
    state.isLoadingKitchenEquipments = false;
    state.fridges = data.data;
  },
  [SET_LOADING_TEMPERATURES](state, value) {
    state.isLoadingKitchenEquipmentTemperatures = value;
  },
  [SET_KITCHEN_EQUIPMENT_TEMPERATURES](state, data) {
    state.isLoadingKitchenEquipmentTemperatures = false;
    state.temperatures = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
