export default [
  {
    path: "/daily-tasks",
    name: "daily-tasks",
    component: () => import("@/modules/daily-tasks/DailyTasks.vue"),
    children: [
      {
        path: "list",
        name: "list-daily-tasks",
        component: () =>
          import("@/modules/daily-tasks/pages/DailyTasksList.vue"),
        meta: { permissions: ["stores-show"] }
      }
    ]
  },
  {
    path: "/store-daily-tasks",
    name: "store-daily-tasks",
    component: () => import("@/modules/daily-tasks/DailyTasks.vue"),
    children: [
      {
        path: "list",
        name: "list-daily-tasks-by-store",
        component: () =>
          import("@/modules/daily-tasks/pages/StoreTasksList.vue"),
        meta: { permissions: ["stores-show"] }
      }
    ]
  }
];
