import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";

//Core
import shared from "./shared.js";

//Core Custom
import lang from "./lang.module";
import imageTypes from "./image_types.module";

//Module Modules
import user from "../../../modules/user/store/user.module";
import healthCard from "../../../modules/health-card/store/health-card.module";
import usersSchedule from "../../../modules/users-schedule/store/users-schedule.module";
import role from "../../../modules/role/store/role.module";
import city from "../../../modules/city/store/city.module";
import setting from "../../../modules/setting/store/setting.module";
import supplier from "../../../modules/supplier/store/supplier.module";
import store from "../../../modules/store/store/store.module";
import item from "../../../modules/item/store/item.module";
import stock from "../../../modules/stock/store/stock.module";
import kitchenEquipments from "../../../modules/kitchen-equipments/store/kitchen-equipments.module";
import dailyTasks from "../../../modules/daily-tasks/store/daily-tasks.module";
import foodTests from "../../../modules/food-tests/store/food-tests.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...shared,
    lang,
    user,
    healthCard,
    usersSchedule,
    role,
    city,
    setting,
    supplier,
    store,
    item,
    stock,
    imageTypes,
    kitchenEquipments,
    dailyTasks,
    foodTests
  }

  // If you want persisted state uncomment this
  // plugins: [
  //   createPersistedState({
  //     paths: ["locationKioskOrders.ordersClockedCode"]
  //   })
  // ]
});
