export default [
  {
    path: "/health-card",
    name: "health-card",
    component: () => import("@/modules/health-card/HealthCard.vue"),
    children: [
      {
        path: "list",
        name: "list-health-cards",
        component: () =>
          import("@/modules/health-card/pages/HealthCardList.vue"),
        meta: { permissions: ["users-show"] }
      }
    ]
  }
];
