import ApiService from "@/core/services/api.service";

//Actions
export const DOWNLOAD_FILE = "downloadFile";

//Mutations
export const SET_LOADING = "setLoadingSharedFile";

const state = {
  isLoadingSharedFile: false
};

const getters = {
  isLoadingSharedFile() {
    return state.isLoadingSharedFile;
  }
};

const actions = {
  [DOWNLOAD_FILE](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("files/get", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedFile = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
