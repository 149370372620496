export default [
  {
    path: "/store",
    name: "store",
    component: () => import("@/modules/store/Store.vue"),
    children: [
      {
        path: "list",
        name: "list-stores",
        component: () => import("@/modules/store/pages/StoreList.vue"),
        meta: { permissions: ["stores-show"] }
      },
      {
        path: "add",
        name: "add-store",
        component: () => import("@/modules/store/pages/StoreAdd.vue"),
        meta: { permissions: ["stores-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-store",
        component: () => import("@/modules/store/pages/StoreEdit.vue"),
        meta: { permissions: ["stores-update"] }
      }
    ]
  }
];
