export default [
  {
    path: "/food-tests",
    name: "food-tests",
    component: () => import("@/modules/food-tests/FoodTests.vue"),
    children: [
      {
        path: "list",
        name: "list-food-tests",
        component: () =>
          import("@/modules/food-tests/pages/FoodTestingList.vue"),
        meta: { permissions: ["stores-show"] }
      }
    ]
  },
  {
    path: "/store-food-tests",
    name: "store-food-tests",
    component: () => import("@/modules/food-tests/FoodTests.vue"),
    children: [
      {
        path: "list",
        name: "list-food-tests-by-store",
        component: () =>
          import("@/modules/food-tests/pages/StoreFoodTestsList.vue"),
        meta: { permissions: ["stores-show"] }
      }
    ]
  }
];
