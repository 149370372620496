import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_HEALTH_CARDS = "fetchHealthCards";
export const FETCH_HEALTH_CARD = "fetchHealthCard";
export const FETCH_USER_HEALTH_CARDS = "fetchUserHealthCards";
export const SAVE_HEALTH_CARD = "saveHealthCard";
export const UPDATE_HEALTH_CARD = "updateHealthCard";
export const DELETE_HEALTH_CARD = "deleteHealthCard";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingHealthCard";
export const SET_HEALTH_CARDS = "setHealthCards";

const state = {
  errors: [],
  healthCards: [],
  isLoadingHealthCard: false
};

const getters = {
  isLoadingHealthCard() {
    return state.isLoadingHealthCard;
  },
  getHealthCards() {
    return state.healthCards;
  }
};

const actions = {
  [FETCH_HEALTH_CARDS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("health-cards" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_HEALTH_CARD](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("health-cards", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_USER_HEALTH_CARDS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("user-health-cards" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_HEALTH_CARD](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("health-cards", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_HEALTH_CARD](context, params) {
    context.commit(SET_LOADING, true);
    console.log(params);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("health-cards", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_HEALTH_CARD](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("health-cards", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingHealthCard = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingHealthCard = value;
  },
  [SET_HEALTH_CARDS](state, data) {
    state.isLoadingHealthCard = false;
    state.healthCards = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
