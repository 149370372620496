export default [
  {
    path: "/supplier",
    name: "supplier",
    component: () => import("@/modules/supplier/Supplier.vue"),
    children: [
      {
        path: "list",
        name: "list-suppliers",
        component: () => import("@/modules/supplier/pages/SupplierList.vue"),
        meta: { permissions: ["suppliers-show"] }
      },
      {
        path: "add",
        name: "add-supplier",
        component: () => import("@/modules/supplier/pages/SupplierAdd.vue"),
        meta: { permissions: ["suppliers-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-supplier",
        component: () => import("@/modules/supplier/pages/SupplierEdit.vue"),
        meta: { permissions: ["suppliers-update"] }
      }
    ]
  }
];
