import Vue from "vue";
import moment from "moment";
import fileService from "@/core/services/file.service.js";
import avatarService from "@/core/services/avatar.service.js";
import i18n from "@/core/plugins/vue-i18n.js";
import { APP_CURRENCY, CURRENCIES } from "@/core/config/config.js";

const formatBalance = val => {
  val = (val / 1).toFixed(2).replace(",", ".");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
};

const formatPrice = val => {
  let abbreviation = i18n.t(`${APP_CURRENCY}`);
  let direction = CURRENCIES[APP_CURRENCY].direction;
  val = formatBalance(val);

  if (direction == "right") {
    return `${val}${abbreviation}`;
  } else {
    return `${abbreviation}${val}`;
  }
};

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("twoLetters", function(value) {
  return typeof value !== "undefined" ? value.charAt(0) + value.charAt(1) : "";
});

Vue.filter("getInitials", function(value) {
  return avatarService.getInitials(value);
});

Vue.filter("getInitialsColor", function(value) {
  return avatarService.getInitialsColor(value);
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY");
  }
});

Vue.filter("formatDateTime", function(value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY HH:mm:ss");
  }
});

Vue.filter("formatTime", function(value) {
  if (value) {
    return moment(String(value)).format("HH:mm:ss");
  }
});

Vue.filter("fileIcon", function(value) {
  if (value) {
    return fileService.getFileIcon(value);
  }
});

Vue.filter("formatBalance", function(value) {
  return formatBalance(value);
});

Vue.filter("formatUnitValue", function(value) {
  let val = (value / 1).toFixed(3).replace(",", ".");
  return val.toString().replace(/\B(?=(\d{4})+(?!\d))/g, "");
});

Vue.filter("formatDecimal6", function(value) {
  let val = (value / 1).toFixed(6).replace(",", ".");
  return val.toString().replace(/\B(?=(\d{4})+(?!\d))/g, "");
});

Vue.filter("twoDigits", value => {
  if (value < 0) {
    return "00";
  }
  if (value.toString().length <= 1) {
    return `0${value}`;
  }
  return value;
});

Vue.filter("hoursAndMinutesToMinutes", function(time) {
  return moment.duration(time).asMinutes();
});

Vue.filter("formatPrice", function(value) {
  return formatPrice(value);
});

Vue.filter("formatBalance", function(value) {
  return formatBalance(value);
});

Vue.filter("ingredientsString", function(recipe) {
  let result = [];

  if (recipe.ingredients.length) {
    recipe.ingredients.forEach(ingredient => {
      result.push(ingredient.name);
    });
  }

  if (recipe.add_topp.length) {
    recipe.add_topp.forEach(ingredient => {
      result.push(
        `${i18n.t(`EXTRA`)} ${ingredient.name} + ${formatPrice(
          ingredient.price
        )}`
      );
    });
  }

  return result.join(", ");
});

Vue.filter("productSettingsString", function(productSettings) {
  if (productSettings.length) {
    let result = "";

    productSettings.forEach(setting => {
      result += `${setting.selectedOption.name}, `;
    });

    return result.slice(0, result.length - 2);
  }

  return "";
});

Vue.filter("mathSign", value => {
  if (value < 0) {
    return "-";
  }
  return "+";
});

Vue.filter("positiveSign", value => {
  if (value >= 0) {
    return "+";
  }
  return "";
});

Vue.filter("formatAddressObject", function(address) {
  let fullAddress = "";
  fullAddress = [
    address.city,
    address.postcode,
    address.street,
    address.streetNumber
  ]
    .filter(Boolean)
    .join(", ");
  return fullAddress;
});

Vue.filter("striphtml", function(value) {
  return value.replace(/<\/?[^>]+>/gi, "");
});

Vue.filter("formatYesNo", function(value) {
  return value ? "Yes" : "No";
});

Vue.filter("showWithComaIfExists", function(value) {
  return value ? value + ", " : "";
});
