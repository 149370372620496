import JwtService from "@/core/services/jwt.service";
import store from "@/core/services/store";
import { REFRESH_USER_DATA } from "@/core/services/store/auth.module";
import { times } from "lodash";
/**
 * Set the default HTTP request headers
 */
const isAuthenticated = () => {
  return !!JwtService.getToken();
};
/**
 * Service to call HTTP request via Axios
 */
const AuthService = {
  init() {
    return new Promise(resolve => {
      if (isAuthenticated()) {
        store.dispatch(REFRESH_USER_DATA).finally(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  },

  refreshUserData() {
    store.dispatch(REFRESH_USER_DATA).finally(() => {});
  }
};

export default AuthService;
