import Vue from "vue";
import Router from "vue-router";

import UserRouter from "@/modules/user/router.js";
import HealthCardRouter from "@/modules/health-card/router.js";
import RoleRouter from "@/modules/role/router.js";
import CityRouter from "@/modules/city/router.js";
import SettingRouter from "@/modules/setting/router.js";
import SupplierRouter from "@/modules/supplier/router.js";
import StoreRouter from "@/modules/store/router.js";
import ItemRouter from "@/modules/item/router.js";
import StockRouter from "@/modules/stock/router.js";
import UsersScheduleRouter from "@/modules/users-schedule/router";
import FridgesRouter from "@/modules/kitchen-equipments/router";
import DailyTasksRouter from "@/modules/daily-tasks/router";
import FoodTests from "@/modules/food-tests/router";
import { authGuard } from "@/guards/auth.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        ...UserRouter,
        ...HealthCardRouter,
        ...UsersScheduleRouter,
        ...RoleRouter,
        ...CityRouter,
        ...SettingRouter,
        ...SupplierRouter,
        ...StoreRouter,
        ...ItemRouter,
        ...StockRouter,
        ...FridgesRouter,
        ...DailyTasksRouter,
        ...FoodTests
      ],
      meta: { requiresAuth: true }
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "password-forgot",
          path: "/password-forgot",
          component: () => import("@/view/pages/auth/Forgot")
        },
        {
          name: "password-reset",
          path: "/password-reset",
          component: () => import("@/view/pages/auth/Reset")
        }
      ],
      meta: { requiresAuth: false }
    },
    {
      path: "*",
      redirect: "/404",
      meta: { requiresAuth: false }
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => {
        return import("@/view/pages/error/Error-4.vue");
      },
      meta: { requiresAuth: false }
    }
  ]
});

router.beforeEach(authGuard);
export default router;
