import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_USERS_SCHEDULE = "fetchUsersSchedule";
export const USERS_SCHEDULE_PDF = "usersSchedulePDF";
export const FETCH_USERS_SCHEDULE_LIST = "fetchUsersScheduleList";
export const FETCH_USERS_WITH_VALID_HC = "fetchUsersWithValidHC";
export const FETCH_MISSING_SCHEDULE_DATES = "fetchMissingScheduleDates";
export const SAVE_USERS_SCHEDULE = "saveUsersSchedule";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingUsersSchedule";
export const SET_USERS_SCHEDULE = "setUsersSchedule";

const state = {
  errors: [],
  usersSchedule: [],
  isLoadingUsersSchedule: false
};

const getters = {
  isLoadingUsersSchedule() {
    return state.isLoadingUsersSchedule;
  },
  getUsersSchedule() {
    return state.usersSchedule;
  }
};

const actions = {
  [FETCH_USERS_SCHEDULE](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("users-schedule" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_USERS_WITH_VALID_HC](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-users-schedule-list" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_USERS_SCHEDULE_LIST](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fetch-users-schedule-list-filtered" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_MISSING_SCHEDULE_DATES](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("fetch-users-schedule-missing-dates", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_USERS_SCHEDULE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("users-schedule", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [USERS_SCHEDULE_PDF](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getPDF("users-schedule-create-pdf" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingUsersSchedule = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingUsersSchedule = value;
  },
  [SET_USERS_SCHEDULE](state, data) {
    state.isLoadingUsersSchedule = false;
    state.usersSchedule = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
