export const PROJECT_NAME = process.env.VUE_APP_PROJECT_NAME;
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
export const API_URL = BASE_API_URL + "api/manage/";
export const APP_CURRENCY = process.env.VUE_APP_CURRENCY || "BGN";
export const CURRENCIES = {
  BGN: {
    direction: "right"
  },
  EUR: {
    direction: "right"
  },
  USD: {
    direction: "left"
  }
};
export var OauthClient = (grant, params) => {
  var clientData = {
    grant_type: grant,
    client_id: 2,
    client_secret: "S0VpWl5BjtBronj5UJd8m6Z7XxGTrr7hr2EOUPFH",
    scope: "full-access"
  };

  if (params && typeof params === "object") {
    clientData = Object.assign(clientData, params);
  }

  return clientData;
};

export default API_URL;
