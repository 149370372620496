export default [
  {
    path: "/setting",
    name: "setting",
    component: () => import("@/modules/setting/Setting.vue"),
    children: [
      {
        path: "list",
        name: "list-settings",
        component: () => import("@/modules/setting/pages/SettingList.vue"),
        meta: { permissions: ["settings-show"] }
      },
      {
        path: "edit/:id",
        name: "edit-setting",
        component: () => import("@/modules/setting/pages/SettingEdit.vue"),
        meta: { permissions: ["settings-update"] }
      }
    ]
  }
];
