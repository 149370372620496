export default [
  {
    path: "/stock",
    name: "stock",
    component: () => import("@/modules/stock/Stock.vue"),
    children: [
      {
        path: "avg-cost-list",
        name: "stock-avg-cost-list",
        component: () => import("@/modules/stock/pages/StockAvgCostList.vue"),
        meta: { permissions: ["stock-actions-show"] }
      }
    ]
  },
  {
    path: "/stock-action",
    name: "stock-action",
    component: () => import("@/modules/stock/Stock.vue"),
    children: [
      {
        path: "list",
        name: "stock-actions-list",
        component: () =>
          import("@/modules/stock/pages/actions/StockActionList.vue"),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "review/:id",
        name: "stock-action-review",
        component: () =>
          import("@/modules/stock/pages/actions/StockActionReview.vue"),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "item-list",
        name: "stock-action-items-list",
        component: () =>
          import("@/modules/stock/pages/actions/StockActionItemList.vue"),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "production",
        name: "stock-production",
        component: () =>
          import("@/modules/stock/pages/actions/StockProduction.vue"),
        meta: { permissions: ["warehouse-production"] }
      },
      {
        path: "receiving",
        name: "stock-receiving",
        component: () =>
          import("@/modules/stock/pages/actions/StockReceiving.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "returning",
        name: "stock-returning",
        component: () =>
          import("@/modules/stock/pages/actions/StockReturning.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "scrapping",
        name: "stock-scrapping",
        component: () =>
          import("@/modules/stock/pages/actions/StockScrapping.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "testing",
        name: "stock-testing",
        component: () =>
          import("@/modules/stock/pages/actions/StockTesting.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "adjustment-supplier",
        name: "stock-adjustment",
        component: () =>
          import("@/modules/stock/pages/actions/StockAdjustmentSupplier.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "wholesale",
        name: "stock-wholesale",
        component: () =>
          import("@/modules/stock/pages/actions/StockWholesale.vue"),
        meta: { permissions: ["stock-wholesale-clients-create"] }
      },
      {
        path: "client-returning",
        name: "stock-client-returning",
        component: () =>
          import("@/modules/stock/pages/actions/StockClientReturning.vue"),
        meta: { permissions: ["stock-wholesale-clients-create"] }
      },
      {
        path: "stock-reference-summary",
        name: "stock-reference-summary",
        component: () =>
          import("@/modules/stock/pages/actions/StockReferenceSummary.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "stock-expense",
        name: "stock-expense",
        component: () =>
          import("@/modules/stock/pages/actions/StockExpense.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "stock-consumptions",
        name: "stock-consumptions",
        component: () =>
          import("@/modules/stock/pages/actions/StockConsumptionList.vue"),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "stock-consumptions-ajur-numbers-list",
        name: "stock-consumptions-ajur-numbers-list",
        component: () =>
          import(
            "@/modules/stock/pages/actions/StockConsumptionAjurNumbersList.vue"
          ),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "stock-consumptions-ajur-number/:id",
        name: "stock-consumptions-ajur-number",
        component: () =>
          import(
            "@/modules/stock/pages/actions/StockConsumptionAjurNumber.vue"
          ),
        meta: { permissions: ["stock-actions-show"] }
      }
    ]
  },

  {
    path: "/stock-invoice",
    name: "stock-invoice",
    component: () => import("@/modules/stock/Stock.vue"),
    children: [
      {
        path: "echo-invoice-list",
        name: "echo-invoices-list",
        component: () =>
          import("@/modules/stock/pages/actions/EchoInvoiceList.vue"),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "invoice-list",
        name: "stock-invoices-list",
        component: () =>
          import("@/modules/stock/pages/actions/StockInvoiceList.vue"),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "review-invoice/:id",
        name: "stock-invoice-review",
        component: () =>
          import("@/modules/stock/pages/actions/StockInvoiceReview.vue"),
        meta: { permissions: ["stock-actions-show"] }
      }
    ]
  },
  {
    path: "/stock-audit",
    name: "stock-audit",
    component: () => import("@/modules/stock/Stock.vue"),
    children: [
      {
        path: "list",
        name: "stock-audits-list",
        component: () =>
          import("@/modules/stock/pages/audits/StockAuditList.vue"),
        meta: { permissions: ["stock-audits-show"] }
      },
      {
        path: "add",
        name: "stock-audit-add",
        component: () =>
          import("@/modules/stock/pages/audits/StockAuditAdd.vue"),
        meta: { permissions: ["stock-audits-create"] }
      },
      {
        path: "edit-last",
        name: "stock-audit-edit-last",
        component: () =>
          import("@/modules/stock/pages/audits/StockAuditEditLast.vue"),
        meta: { permissions: ["stock-audits-create"] }
      },
      {
        path: "review/:id",
        name: "stock-audit-review",
        component: () =>
          import("@/modules/stock/pages/audits/StockAuditReview.vue"),
        meta: { permissions: ["stock-audits-show"] }
      }
    ]
  },
  {
    path: "/stock-credit-debit",
    name: "stock-credit-debit",
    component: () => import("@/modules/stock/Stock.vue"),
    children: [
      {
        path: "list",
        name: "stock-credit-debit-action-list",
        component: () =>
          import(
            "@/modules/stock/pages/actions/StockCreditDebitActionList.vue"
          ),
        meta: { permissions: ["stock-actions-show"] }
      },
      {
        path: "add",
        name: "stock-credit-debit-action-add",
        component: () =>
          import("@/modules/stock/pages/actions/StockCreditDebitActionAdd.vue"),
        meta: { permissions: ["stock-actions-create"] }
      },
      {
        path: "review/:id",
        name: "stock-credit-debit-action-review",
        component: () =>
          import(
            "@/modules/stock/pages/actions/StockCreditDebitActionReview.vue"
          ),
        meta: { permissions: ["stock-actions-show"] }
      }
    ]
  },
  {
    path: "/stock-purchase-order",
    name: "stock-purchase-order",
    component: () => import("@/modules/stock/Stock.vue"),
    children: [
      {
        path: "list",
        name: "stock-purchase-orders-list",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderList.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-show"] }
      },
      {
        path: "create",
        name: "stock-purchase-order-create",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderCreate.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-create"] }
      },
      {
        path: "review/:id",
        name: "stock-purchase-order-review",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderRequestReview.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-show"] }
      },
      {
        path: "list-new",
        name: "stock-purchase-orders-list-new",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderListNew.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-show"] }
      },
      {
        path: "list-processing",
        name: "stock-purchase-orders-list-processing",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderListProcessing.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-show"] }
      },
      {
        path: "list-ready",
        name: "stock-purchase-orders-list-ready",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderListReady.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-show"] }
      },
      {
        path: "list-receiving",
        name: "stock-purchase-orders-list-receiving",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderListReceivingPage.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-show"] }
      },
      {
        path: "list-discrepancy",
        name: "stock-purchase-orders-list-discrepancy",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderListDiscrepancyPage.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-discrepancy"] }
      },
      {
        path: "prepare/:id",
        name: "stock-purchase-order-prepare",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderPrepare.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-update"] }
      },
      {
        path: "receiving/:id",
        name: "stock-purchase-order-receiving",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderReceiving.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-update"] }
      },
      {
        path: "discrepancy/:id",
        name: "stock-purchase-order-discrepancy",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderDiscrepancy.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-discrepancy"] }
      },
      {
        path: "production",
        name: "stock-purchase-order-production",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockPurchaseOrderProduction.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-create"] }
      },
      {
        path: "processing",
        name: "stock-processing-order-items",
        component: () =>
          import(
            "@/modules/stock/pages/purchase-orders/StockProcessingOrderItems.vue"
          ),
        meta: { permissions: ["stock-purchase-orders-create"] }
      }
    ]
  },
  {
    path: "/stock-wholesale-client",
    name: "stock-wholesale-client",
    component: () => import("@/modules/stock/Stock.vue"),
    children: [
      {
        path: "list",
        name: "list-stock-wholesale-clients",
        component: () => import("@/modules/stock/pages/clients/ClientList.vue"),
        meta: { permissions: ["stock-wholesale-clients-show"] }
      },
      {
        path: "add",
        name: "add-stock-wholesale-client",
        component: () => import("@/modules/stock/pages/clients/ClientAdd.vue"),
        meta: { permissions: ["stock-wholesale-clients-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-stock-wholesale-client",
        component: () => import("@/modules/stock/pages/clients/ClientEdit.vue"),
        meta: { permissions: ["stock-wholesale-clients-update"] }
      }
    ]
  }
];
