import _ from "lodash";
import i18n from "@/core/plugins/vue-i18n";

const fileService = {
  fileIcons: [
    { extension: ".xlsx", icon: "mdi-file-excel" },
    { extension: ".docx", icon: "mdi-file-word" },
    { extension: ".pdf", icon: "mdi-file-pdf" }
  ],

  /**
   * Get file icon by file extension
   * @param {string} extension
   * return {string}
   */
  getFileIcon(extension) {
    if (_.find(this.fileIcons, { extension: extension })) {
      return _.find(this.fileIcons, { extension: extension }).icon;
    } else {
      return "mdi-file";
    }
  },

  /**
   * Validate file
   *
   * @param {File} file - file to be validated
   * @param {object} rules - validation rules (required: boolean, size: integer in kilobytes, fileTypes: array[string])
   * @param {string} field - translated field name to be shown in error messages
   *
   * @return {object}
   */
  validateFile(file, rules, field) {
    const hasRequiredRule = Object.prototype.hasOwnProperty.call(
      rules,
      "required"
    );

    if (hasRequiredRule && rules.required === true && !file) {
      let error = i18n.t("REQUIRED", {
        name: field
      });

      return { valid: false, error: error };
    }

    if (!file) {
      return { valid: true, error: null };
    }

    if (rules.size) {
      const sizeInBytes = rules.size * 1024;

      if (file.size > sizeInBytes) {
        let error = i18n.t("FILE_BIG_SIZE", {
          value: formatBytes(sizeInBytes)
        });

        return { valid: false, error: error };
      }
    }

    if (rules.fileTypes && rules.fileTypes.length > 0) {
      const extension = file.name.split(".").pop();

      if (_.indexOf(rules.fileTypes, extension) === -1) {
        let error = i18n.t("FILE_TYPE", {
          value: rules.fileTypes.join(", ")
        });

        return { valid: false, error: error };
      }
    }

    return { valid: true, error: null };
  }
};

export default fileService;

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
