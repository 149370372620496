export default [
  {
    path: "/city",
    name: "city",
    component: () => import("@/modules/city/City.vue"),
    children: [
      {
        path: "list",
        name: "list-cities",
        component: () => import("@/modules/city/pages/CityList.vue"),
        meta: { permissions: ["cities-show"] }
      },
      {
        path: "add",
        name: "add-city",
        component: () => import("@/modules/city/pages/CityAdd.vue"),
        meta: { permissions: ["cities-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-city",
        component: () => import("@/modules/city/pages/CityEdit.vue"),
        meta: { permissions: ["cities-update"] }
      }
    ]
  }
];
