import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";
// import NotifyService from "@/core/services/notify.service";

// action types
export const LOGIN = "login";
export const LOGIN_TWO_FACTOR = "loginTwoFactor";
export const LOGOUT = "logout";
export const REFRESH_USER_DATA = "refreshUserData";
export const EMAIL = "email";
export const FORGOT = "forgot";
export const RESET = "reset";
export const TWOFACTOR = "twoFactor";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_SUCCESS = "setSuccess";
export const SET_USER_DATA = "setUserData";
export const UPDATE_USER_DATA = "updateUserData";
export const SET_BADGES_DATA = "setBadgesData";

export const PASSWORD_GRANT = "password";
export const REFRESH_TOKEN_GRANT = "refresh_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

const state = {
  errors: [],
  success: "",
  user: {},
  badges: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_ERROR, []);
          if (data) {
            context.commit(SET_AUTH, data);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          console.log(response);
          if (response.status === 400) {
            response.data.errors.info = response.data.error;
          }
          context.commit(SET_ERROR, response.data.message);
          reject(response);
        });
    });
  },
  [LOGIN_TWO_FACTOR](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login-two-factor", credentials)
        .then(({ data }) => {
          // console.log("xxx");
          // context.commit(SET_ERROR, []);
          // if (data) {
          context.commit(SET_AUTH, data);
          context.commit(SET_BADGES_DATA, data.data.badges);
          resolve(data);
          // }
        })
        .catch(({ response }) => {
          console.log(response);
          if (response.status === 400) {
            response.data.errors.info = response.data.error;
          }
          context.commit(SET_ERROR, response.data.message);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("logout")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data?.errors?.info);
          reject();
        })
        .finally(() => {
          context.commit(PURGE_AUTH);
        });
    });
  },
  [FORGOT](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("password-forgot", credentials)
        .then(({ data }) => {
          context.commit(SET_ERROR, []);
          if (data) {
            context.commit(SET_SUCCESS, data.message);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [RESET](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("password-reset", credentials)
        .then(({ data }) => {
          context.commit(SET_ERROR, []);
          if (data) {
            context.commit(SET_SUCCESS, data.message);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [TWOFACTOR](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("two-factor/send", payload)
        .then(() => {
          context.commit(SET_ERROR, []);
          resolve();
        })
        .catch(({ response }) => {
          // console.log(response);
          if (response?.data?.status === 404) {
            response.data.errors.info = response.data.error;
          }
          context.commit(
            SET_ERROR,
            response?.data?.errors?.info || "Възникна грешка!"
          );
          reject(response);
        });
    });
  },
  [REFRESH_USER_DATA](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("refresh-user-data")
        .then(({ data }) => {
          context.commit(UPDATE_USER_DATA, data.data.userData);
          context.commit(SET_BADGES_DATA, data.data.badges);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          //Is not working as expected...is not loading the page Login - it's blank
          router.push("login");
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_SUCCESS](state, success) {
    state.success = success;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.data.userData;
    state.errors = [];
    JwtService.saveTokens(data.data);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyTokens();
  },
  [UPDATE_USER_DATA](state, data) {
    state.user = data;
    JwtService.updateUserData(data);
  },
  [SET_BADGES_DATA](state, data) {
    state.badges = data;
  },
  [SET_USER_DATA](state, data) {
    state.user = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
