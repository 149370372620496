export default [
  {
    path: "/item",
    name: "item",
    component: () => import("@/modules/item/Item.vue"),
    children: [
      {
        path: "list",
        name: "list-items",
        component: () => import("@/modules/item/pages/item/ItemList.vue"),
        meta: { permissions: ["items-show"] }
      },
      {
        path: "list-products",
        name: "list-products",
        component: () => import("@/modules/item/pages/item/ProductsList.vue"),
        meta: { permissions: ["items-show"] }
      },
      {
        path: "list-ingredients",
        name: "list-ingredients",
        component: () =>
          import("@/modules/item/pages/item/IngredientsList.vue"),
        meta: { permissions: ["items-show"] }
      },
      {
        path: "list-consumables",
        name: "list-consumables",
        component: () =>
          import("@/modules/item/pages/item/ConsumablesList.vue"),
        meta: { permissions: ["items-show"] }
      },
      {
        path: "add",
        name: "add-item",
        component: () => import("@/modules/item/pages/item/ItemAdd.vue"),
        meta: { permissions: ["items-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-item",
        component: () => import("@/modules/item/pages/item/ItemEdit.vue"),
        meta: { permissions: ["items-update"] }
      }
    ]
  }
];
