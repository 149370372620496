import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_USERS = "fetchUsers";
export const FETCH_USER = "fetchUser";
export const SAVE_USER = "saveUser";
export const UPDATE_USER = "updateUser";
export const DELETE_USER = "deleteUser";
//On Work
export const FETCH_USERS_ON_WORK = "fetchUsersOnWork";
export const FETCH_USERS_ON_WORK_PERIOD = "fetchUsersOnWorkPeriod";
export const FETCH_USER_CLOCK_TRACK = "fetchUserClockTrack";
export const UPDATE_USER_CLOCK_TRACK = "updateUserClockTrack";
//Drivers
export const DO_TRANSFER_MONEY = "doTransferMoney";
export const CLOCK_OUT_AND_TRANSFER_MONEY = "clockOutAndTransferMoney";
export const CHECK_CLOCK_OUT_AND_TRANSFER_MONEY =
  "checkClockOutAndTransferMoney";
export const GET_USERS_CONTRACT = "getUsersContract";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingUser";
export const SET_USERS = "setUsers";

const state = {
  errors: [],
  users: [],
  isLoadingUser: false
};

const getters = {
  isLoadingUser() {
    return state.isLoadingUser;
  },
  getUsers() {
    return state.users;
  }
};

const actions = {
  [FETCH_USERS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("users" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_USER](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("users", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_USER](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("users", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_USER](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("users", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_USER](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("users", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_USERS_ON_WORK](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("clock-tracks" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_USERS_ON_WORK_PERIOD](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("clock-tracks-for-period" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_USER_CLOCK_TRACK](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("clock-tracks", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_USER_CLOCK_TRACK](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("clock-tracks", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DO_TRANSFER_MONEY](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("transfer-money", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [CLOCK_OUT_AND_TRANSFER_MONEY](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("clock-out-and-transfer-money", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [CHECK_CLOCK_OUT_AND_TRANSFER_MONEY](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("check-clock-out-and-transfer-money", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_USERS_CONTRACT](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("get-users-contract")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingUser = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingUser = value;
  },
  [SET_USERS](state, data) {
    state.isLoadingUser = false;
    state.users = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
