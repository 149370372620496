// BG
export const locale = {
  APP_TITLE: "DominosLink",
  SELECT: "Избери своя език",
  NOTIFY: {
    DEFAULT_ERROR: "Възникна грешка",
    DEFAULT_SUCCESS: "Успешно извършено действие",
    COMBO_MISSING_PRODUCTS_ERROR: "Моля изберете всички необходими продукти",
    INVALID_PROMO_CODE: "Невалиден промоционален код",
    STORE_CLOSED: "Работното време на магазините е приключило",
    CART_EXPIRED: "Поръчката е изтекла",
    POINT_IS_NOT_IN_LOCATION: "Адреса е извън обсега на доставки"
  },
  BGN: "лв.",
  EUR: "евро",
  USD: "$",
  SETTINGS: "Настройки",
  PRODUCTS: "Продукти",
  ORDERS: "Поръчки",
  WAREHOUSE: "Склад",
  TESTED_FOOD: "Изтествани Храни",
  FOOD_TESTS: "Храна за тестване",
  DASHBOARD: "Начало",
  LIST: "Списък",
  ADD: "Добавяне",
  EDIT: "Редактиране",
  DUPLICATE: "Дублиране",
  KITCHEN_EQUIPMENTS: "Уреди",
  DAILY_TASKS: "Дневни Задачи",
  STATISTICS: "Статистики",
  STATISTICS_GENERAL: "Статистики Общи",
  STATISTICS_GROUPS: "Статистики Групи",
  STATISTICS_LC_FC: "Статистики LC&FC",
  STATISTICS_DASHBOARD: "Табло",
  SALES_LOCATION: "Продажби за локация",
  SALES_STORE: "Продажби за магазин",
  SALES_BY_GROUPS_LOCATION: "Продажби по групи за локация",
  SALES_BY_GROUPS_STORE: "Продажби по групи за магазин",
  SALES_BY_GROUPS_BRAND: "Продажби по групи за бранд",
  SALES_BY_DAYS: "Продажби по дни",
  DELAYED_ORDERS: "Закъснели поръчки",
  LC_FC_MONTHLY_STORE: "Месечен LC&FC магазин",
  LC_FC_MONTHLY_LOCATION: "Месечен LC&FC локация",
  LC_FC_DAILY_STORE: "Дневен LC&FC магазин",
  LC_FC_DAILY_LOCATION: "Дневен LC&FC локация",
  USER_CONTRACT: "Потребители за предоговаряне на договор",
  USERS: "Потребители",
  USERS_ON_WORK: "На работа",
  USERS_ON_WORK_PERIOD: "На работа за период",
  DRIVERS_LIST: "Шофьори",
  CUSTOMERS: "Клиенти",
  GUESTS: "Гости",
  LOCATIONS: "Локации",
  BRANDS: "Фирми/Брандове",
  STORES: "Магазини",
  GENERAL: "Други настройки",
  ROLES: "Роли",
  CITIES: "Градове",
  SECTION_PAGES: "Страници",
  SECTION_PAGE_TEXTS: "Страница Текстове",
  SECTION_PAGE_BANNERS: "Страница Банери",
  HEALTH_CARDS: "Здравни Книжки",
  USERS_SCHEDULE: "График Служители",
  FOOD_TESTING: "Храни За Тестване",
  ALL_ITEMS: "Продукти/Съставки",
  INGREDIENTS: "Съставки",
  CONSUMABLES: "Консумативи",
  ITEM_CATEGORIES: "Категории",
  RECIPES: "Рецепти",
  PRICES: "Цени",
  PROMOTIONS: "Промоции",
  SIZES: "Размери",
  PRODUCT_SETTINGS: "Опции към продукта",
  FEATURED_PRODUCTS: "Промотирани продукти",
  ORDERS_REVIEW: "Преглед поръчка",
  NOT_VALIDATED: "Невалидирани поръчки",
  CLOCK_IN_OUT: "Clock In/Out",
  STORE_KIOSK: "Екран Магазин",
  MAKELINE: "Makeline",
  PACKLINE: "Packline",
  LOCATION_KIOSK: "Екран Локация",
  PICKUPLINE: "Pickupline",
  DRIVER_KIOSK: "Екран Шофьор",
  DELIVERYLINE: "Deliveryline",
  DRIVER_CLOCK_IN: "Clock In",
  DRIVER_IN: "Вписване в обекта",
  MAKE_ME_ACTIVE: "Направи ме активен",
  MARK_ORDER_DELIVERED: "Маркирай като Доставена",
  ORDER_CALL_CENTER: "Екран Кол Център",
  PROVIDERS: "Доставчици",
  ITEMS_IN_STOCK: "Продукти на склад",
  ACTIONS: "Действия",
  INVOICES: "Фактури",
  MOVEMENTS: "Движения",
  MOVEMENT_ITEMS: "Движения на продукти/съставки",
  AUDITS: "Одити",
  NEW_AUDIT: "Нов Одит",
  ACTION_REVIEW: "Преглед на Движение",
  AUDIT_REVIEW: "Преглед на Одит",
  STOCK_PURCHASE_ORDER_REQUEST_REVIEW: "Преглед на Заявка",
  STOCK_PURCHASE_ORDER_PREPARE: "Подготовка на Заявка",
  EDIT_LAST_AUDIT: "Редакция на последен Одит",
  PURCHASE_ORDERS: "Заявки за доставка",
  STOCK: "Стока",
  STOCK_PRODUCТION: "Производство",
  STOCK_RECEIVING: "Получаване на стока",
  STOCK_RECEIVING_SUPPLIER: "Получаване от доставчик",
  STOCK_DISCREPANCY: "Движение със несъответствия",
  STOCK_RECEIVING_STORE: "Получаване от магазин",
  STOCK_RETURNING: "Връщане на стока",
  STOCK_ADJUSTMENT_SUPPLIER: "Корекция от Доставчик",
  STOCK_REDIRECTING: "Пренасочване на стока",
  STOCK_SCRAPPING: "Бракуване на стока",
  STOCK_TESTING: "Стока за тестове",
  STOCK_AVERAGE_COST_LIST: "Средни стойности",
  STOCK_PURCHASE_ORDER: "Заявка за доставка",
  STOCK_PURCHASE_ORDERS: "Заявки за доставка",
  STOCK_WHOLESALE: "Продажби",
  STOCK_WHOLESALE_CLIENTS: "Клиенти",
  NEW_STOCK_PURCHASE_ORDER: "Направи нова заявка",
  LIST_NEW_STOCK_PURCHASE_ORDERS: "Нови",
  LIST_PROCESSING_STOCK_PURCHASE_ORDERS: "Обработващи се",
  LIST_READY_STOCK_PURCHASE_ORDERS: "Готови за изпращане",
  LIST_RECEIVING_STOCK_PURCHASE_ORDERS: "За получаване",
  LIST_DISCREPANCY_STOCK_PURCHASE_ORDERS: "Несъответствия",
  STOCK_DOUGH: "Производство - Тесто",
  STOCK_REFERENCE_SUMMARY_DOC: "Справка за наличности - Сумарна по МЗ",
  STOCK_EXPENSE_DOC: "Справка разходи",
  NEW: "Нов",
  SEND: "Изпрати",
  READY: "Готово",
  BACK: "Назад",
  CANCEL: "Oтмени",
  ADD_NEW: "Добави нов",
  ADD_NEW_F: "Добави нова",
  DELETE: "Изтрий",
  REMOVE: "Премахни",
  SAVE: "Запази",
  SAVE_AND_CONTINUE: "Запази и продължи",
  SAVE_AND_ADD_NEW: "Запази и добави нов",
  SAVE_AND_EXIT: "Запази и излез",
  SET_NEW_PASSWORD: "Промени Паролата",
  NO_NEW_PASSWORD: "Не Променяй Паролата",
  GENERATE_PASSWORD: "Генерирай Парола",
  GENERATE: "Генерирай",
  CONTINUE: "Продължи",
  UPLOAD: "Качи",
  REFRESH: "Презареди",
  FILES: "Файлове",
  DOWNLOAD: "Свали",
  EXPORT: "Експорт",
  SAVE_AND_EXPORT: "Запази като файл и Експорт",
  CLOSE: "Затвори",
  CONFIRM: "Потвърди",
  ALL: "Всички",
  APPLY: "Приложи",
  APPLY_FOR_ALL_STORES: "Приложи за всички магазини",
  CREATE: "Създай",
  PLEASE_CONFIRM: "Моля потвърдете",
  YES: "Да",
  NO: "Не",
  COMPLETE: "Завърши",
  EXIT: "Изход",
  ORDER: "Поръчка",
  CARRY_OUT: "Вземане от място",
  DELIVERY: "Доставка",
  NOW: "Сега",
  LATER: "По късно",
  MODIFY: "Модифицирай",
  ADD_PROMOTION_CODE: "Добави промо код",
  ADD_ADDRESS: "Нов адрес",
  ADD_DISCOUNT: "Добави намаление",
  DISCOUNT: "Намаление",
  DISCOUNTS: "Намаления",
  READY_TO_SHIP: "Готово за изпращане",
  BACK_TO_PROCESSING: "Върни за обработка",
  SHIP: "Изпрати",
  REJECT: "Отхвърли",
  ITEMS_PER_PAGE_TEXT: "Резултати на страница",
  NO_ACTION_RIGHTS: "Няма права",
  FOOTER_LEFT: "DominosLink",
  WELCOME: "DominosLink",
  WELCOME_DESCRIPTION: "Платформа за управление",
  SIGNUP_BUTTON: "Вход",
  BACK_BUTTON: "Назад",
  PRIVACY: "Политика за поверителност",
  FACEBOOK_LOGIN: "Продължи с Facebook",
  TITLE_LOGIN: "Вход",
  DESCRIPTION_LOGIN:
    "Моля свържете се с администратор за да получите потребителско име и парола",
  TWO_FACTOR: "Моля въведете четирицифрения код, изпратен на вашия имейл.",
  VALID_EMAIL: "Въведете валиден имейл.",
  VALID_PASSWORD: "Паролата е задължителна.",
  VALID_PASSWORD_CONFIRM: "Паролите не съвпадат.",
  BUTTON: "Вход",
  TITLE_TFA: "2FA",
  DESCRIPTION_TFA:
    "За да продължите, въведете 6 цифрения код изпратен на вашия имейл!",
  CODE: "Код",
  VALID_CODE: "Въведете валиден код.",
  TITLE_FORGOT_PASSWORD: "Забравена парола?",
  SUCCESS_FORGOT_PASSWORD:
    "Ще получите имейл с линк за рестартиране на паролата си.",
  DESC_FORGOT_PASSWORD: "Въведете вашия имейл, за да рестартирате паролата си!",
  TITLE_RESET_PASSWORD: "Въведете вашата нова парола",
  DESC_RESET_PASSWORD: "Въведете вашия имейл, за да рестартирате паролата си!",
  SUCCESS_RESET_PASSWORD: "Вашата парола беше успешно рестартирана.",
  TITLE_REGISTER: "Регистрация",
  DESC_REGISTER: "Въведете вашите данни, за да създадете акаунт",
  SUCCESS_REGISTER: "Вашият акаунт е създаден успешно.",
  EMAIL: "Имейл",
  FULLNAME: "Имена",
  PASSWORD: "Парола",
  CONFIRM_PASSWORD: "Потвърдете парола",
  USERNAME: "Потребителско име",
  ERROR: "Грешка",
  VALID: "Looks Good!",
  INVALID: "Полето {name} е невалидно",
  REQUIRED: "Полето {name} е задължително",
  REQUIRED_FIELD: "Полето е задължително",
  LENGTH: "Полето {name} трябва да е {length} символа",
  MIN_LENGTH: "Полето {name} трябва да е минимум {min} символа",
  MIN_VALUE: "Полето {name} трябва да е с минимум стойност {min}",
  AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
  NOT_FOUND: "The requested {name} is not found",
  INVALID_LOGIN: "The login detail is incorrect",
  MIN_LENGTH_FIELD: "Minimum field length:",
  MAX_LENGTH_FIELD: "Maximum field length:",
  INVALID_FIELD: "Field is not valid",
  INVALID_FILE: "Файла не е валиден",
  NO_RESULTS: "Няма намерени резултати.",
  FILE_BIG_SIZE: "Файла е твърде голям. Позволена големина: {value}",
  FILE_TYPE: "Непозволен тип на файл. Позволени типове: {value}",
  CLOCKED_OUT: "Не сте клокнати в системата",
  MISSING_PRODUCT_SETTINGS_OPTIONS: "Моля добавете опции",
  MISSING_PRODUCT_SETTINGS_OPTIONS_TRANSLATIONS:
    "Моля попълнете всички преводи за опциите",
  TITLE_PROFILE: "Потребителски Профил",
  HI: "Здравей",
  LOGOUT: "Изход",
  MY_PROFILE: "Моя профил",
  PROFILE_SETTINGS: "Настройки на профила",
  CUSTOMER_NAME: "Клиент",
  FIRST_NAME: "Име",
  LAST_NAME: "Фамилия",
  CONTRACT_DATE: "Дата на договора",
  PHONE: "Телефон",
  STREET: "Улица/Квартал",
  STREET_NUMBER: "Номер",
  POSTCODE: "Пощенски код",
  DOOR_BELL: "Звънец",
  FLOOR: "Етаж",
  BLOCK: "Блок",
  ENTRANCE: "Вход",
  APARTMENT: "Апартамент",
  CITY: "Град",
  NOTES: "Бележки",
  VALID_NAME: "Въведете валидно име.",
  VALID_SLUG: "Въведете валиден slug.",
  VALID_FIRST_NAME: "Въведете валидно име.",
  VALID_LAST_NAME: "Въведете валиднa фамилия.",
  VALID_CONTRACT_DATE: "Въведете валидна дата на договора.",
  VALID_ACTIVE: "Въведете валиднa стойност за активен:",
  VALID_ROLE: "Въведете валиднa роля:",
  VALID_SHORT_DESCRIPTION: "Въведете валидно кратко описание.",
  VALID_BRAND: "Въведете валиден бранд.",
  VALID_PRODUCT_SETTING_OPTIONS: "Въведете валидни опции.",
  VALID_PRODUCT_SETTING_OPTION: "Въведете валидна опция.",
  VALID_VALUE: "Въведете валидна стойност.",
  DATE: "Дата",
  TASK_HOURLY_REPEATED:
    "Повтаряне през X час/часа (ПРАЗНО ако e веднъж на ден)",
  TASK_IS_WAREHOUSE: "Склад",
  IS_WAREHOUSE: "Склад?",
  TASK_DONE: "Завърши",
  COMMENT: "Коментар по задачата (не задължително поле)",
  DAILY_TASK: "Задачата изпълнена за обект",
  DAILY_TASK_CREATE: "Създаване на дневна задача",
  DAILY_TASK_EDIT: "Редактиране на дневна задача",
  NAME: "Име:",
  ACTIVE: "Активен:",
  ROLE: "Роля:",
  PERMISSIONS: "Права:",
  TEMPERATURE: "Температура",
  KITCHEN_EQUIPMENT: "Уред",
  KITCHEN_EQUIPMENT_TYPE: "Уред - Тип",
  HEALTH_CARD: "Здравна Книжка",
  HEALTH_CARD_VALID: "Валидна До:",
  HEALTH_CARD_VALID_FROM: "Валидна От:",
  EMPLOYEES: "Служители на работа",
  USERS_SCHEDULE_DATE: "Дата",
  STORE: "Магазин:",
  SUPPLIER: "Доставчик:",
  SUPPLIERS: "Доставчици:",
  CLIENTS: "Клиенти:",
  CLIENT: "Клиент:",
  FORWARDERS: "Транспорт:",
  FORWARDER: "Транспорт:",
  CONTRACTORS: "Изпълнител:",
  CONTRACTOR: "Изпълнител:",
  CUSTOMS: "Митница:",
  PROVIDER: "Доставчик:",
  LAT: "Latitude:",
  LNG: "Longitude:",
  LOCATION: "Местоположение:",
  ADDRESS: "Адрес:",
  DESCRIPTION: "Описание:",
  SHORT_DESCRIPTION: "Кратко описание:",
  SEARCH_PLACE: "Търси място:",
  DELIVERY_POLYGON: "Район за доставка координати:",
  BRAND: "Бранд:",
  MINUTES_MAKELINE: "Makeline Minutes:",
  MINUTES_DELIVERY: "Време за доставка в минути:",
  MINUTES_CARRY_OUT: "CarryOut Minutes:",
  WORK_DAY: "Работен ден ДА/НЕ:",
  STORE_OPEN: "Отворено:",
  STORE_CLOSE: "Затворено:",
  FROM: "От:",
  NOT_FROM: "Не от:",
  TO: "До:",
  NOT_TO: "Не до:",
  FOR: "За:",
  SLUG: "Slug:",
  TEXT: "Текст:",
  CLOCK_CODE: "Clock Code:",
  PASS_CODE: "Pass Code:",
  HOURLY_RATE: "Hourly Rate:",
  LANG: "Език:",
  FOR_BRAND: "За Бранд:",
  SELECT_ALL: "Избери всички",
  NO_SELECTED: "Няма избрани",
  SELECTED: "Избрани:",
  ALL_SELECTED: "Всички са избрани",
  ITEM: "Продукт/Съставка:",
  ITEM_CATEGORY: "Категория:",
  ITEM_CATEGORY_TYPE: "Тип категория:",
  ITEM_RECIPE_TYPE: "Тип съставка към рецепта:",
  ITEM_RECIPE_MAIN: "Главна съставка към рецепта:",
  ITEM_RECIPE_ADDON: "Добавка към рецепта:",
  ITEM_WAREHOUSE: "Склад",
  ITEM_PACKAGE: "Опаковка",
  MAIN_BASE_INGREDIENTS: "Главна базова съставка:",
  MAIN_INGREDIENTS: "Главна съставка:",
  ADDON_INGREDIENTS: "Добавка съставка:",
  DEFAULT_COST: "Стойност в лв. (Cost):",
  PRICE: "Цена:",
  ADDON_PRICE: "Цена на добавка:",
  DIFFERENT_PRICE: "Различна цена?",
  UNIT_VALUE: "Мерна единица стойност:",
  UNIT_VALUE_IN_PACKAGE: "Мерна единица в опаковка:",
  UNIT: "Мерна единица:",
  HAS_SIZE: "С размери?",
  PRICES_AND_SIZES: "Цени и Размери",
  PRICES_AND_PRODUCTS: "Продукти и Цени",
  RECIPE: "Рецепта",
  IS_RECIPE: "С рецепта?",
  IS_DOUGH: "Тесто?",
  IS_CONSUMPTION: "На разход?",
  SHORTCODE: "Съкращение:",
  SORT: "Подредба:",
  TITLE: "Заглавие:",
  ICON: "Иконка:",
  BANNER: "Банер:",
  IMG: "Снимка:",
  ONCE_PER_CUSTOMER: "Един път на клиент:",
  FOR_DELIVERY: "За Доставка:",
  FOR_CARRY_OUT: "За Взимане от място:",
  FOR_MOBILE: "За Mobile App:",
  FOR_WEB: "За Web App:",
  FOR_KIOSK: "За Каса:",
  FOR_CALL_CENTER: "За Кол Център:",
  VALIDITY_TIMES: "Валидност в пъти (0 = без лимит)",
  MIN_TOTAL_PRICE: "Минимална обща цена на количката",
  VALID_RANGE: "Дата от - до:",
  PROMOTION: "Промоция:",
  PROMOTION_TYPE: "Тип промоция:",
  PROMOTION_VARIANT: "Вариант на промоцията:",
  PROMOTION_DISCOUNT_VARIANT: "Вариант на намаление:",
  PROMOTION_SLOTS: "Слотове на промоцията:",
  PROMOTION_CODE: "Промо код:",
  PROMOTION_CODES: "Промо кодове:",
  VALUE: "Стойност:",
  SLOT_NAME: "Име на слота:",
  ITEMS_FROM_SLOT: "Брой продукти от слота:",
  USER: "Потребител",
  USER_GROUPS: "Потребителски Групи",
  CUSTOMER_GROUPS: "Клиентски Групи",
  NUMBER_OF_CODES: "Брой кодове:",
  CREATED_AT: "Създаден на",
  UPDATED_AT: "Обновен на",
  UPDATED_BY: "Обновен от",
  IMAGE: "Снимка",
  IMAGES: "Снимки",
  HOMEPAGE_IMAGE: "Начална страница",
  LOGO: "Лого",
  SQUARE_LOGO: "Квадратно Лого",
  CARRY_OUT_VAT_GROUP: "ДДС Група за взимане от място",
  DELIVERY_VAT_GROUP: "ДДС Група за доставка",
  DETAILS: "Детайли",
  IS_DEFAULT: "Default",
  TRACK_CHANGES: "Проследяване на промени",
  MAIL_HISTORY: "История на мейлите",
  ORDERS_HISTORY: "Поръчки",
  ORDER_METHODS: "Методи на поръчка:",
  PAYMENT_METHODS: "Методи на плащане:",
  ORDER_STATUSES: "Статуси на поръчката:",
  ORDER_SOURCE_TYPES: "Поръчано от:",
  DRIVER_MAX_ORDERS: "Максимум бр. поръчки в шофьор",
  DRIVER_ORDERS_AWAIT_TIME: "Време за изчакване на шофьор в секунди",
  DELIVERY_PRICE: "Цена за доставка",
  DELIVERY_FREE_AFTER_PRICE: "Безплатна доставка след цена",
  HAS_DELIVERY_FREE_AFTER_PRICE: "Има Безплатна доставка след цена?",
  NEWSLETTER: "Newsletter",
  BANNER_GROUP: "Група банери:",
  SHOW_TEXT: "Показване на текста:",
  NUM_LAST_DAYS: "През последните Х дни",
  MIN_NUM_OF_ORDERS: "Мин бр. поръчки",
  MAX_NUM_OF_ORDERS: "Макс бр. поръчки",
  MIN_AVG_ORDER_TOTAL_PRICE: "Мин средна цена поръчка",
  MAX_AVG_ORDER_TOTAL_PRICE: "Макс средна цена поръчка",
  CASH_DESKS: "Каси:",
  CASH_DESK_TRANSFER: "Въвеждане/Извеждане",
  CASH_DESK_TRACK: "Движения на каса",
  IP: "IP",
  PORT: "PORT",
  ITEM_TYPE: "Тип",
  IS_VISIBLE: "Видим в публична част",
  PRODUCT_SETTINGS_SHORT: "Опции",
  PRODUCT_SETTING: "Опция",
  PRODUCT_SETTING_OPTIONS: "Опции",
  SETTING_TYPE: "Тип настройка",
  FEATURED_PRODUCTS_MANUAL_MODE: "Ръчен режим",
  TOTAL: "Общо",
  IS_ADMIN: "Админ?",
  METHOD: "Метод",
  DEVICE: "Уред",
  COOKING_TIME: "Готвене (минути)",
  TEMPERATURE_MIN: "Минимална Температура",
  TEMPERATURE_MAX: "Максимална Температура",
  FOOD_TEST_CREATE: "Храна за тестване (създаване)",
  STORE_FOOD_TESTS: "Тестване На",
  FROM_STORE: "От магазин",
  TO_STORE: "До магазин",
  STATUS: "Статус",
  NUMBER: "Номер",
  BULSTAT: "ЕИК",
  VAT_NUMBER: "ДДС Номер",
  OWNER: "МОЛ",
  WORKS_WITH_DELIVERY_NOTE: "Със Стокова Разписка?",
  CLIENT_STORE: "Обект на клиент",
  STOCK_WHOLESALE_CLIENT_STORES: "Обекти на клиент",
  CODE_MZ: "Шифър МЗ",
  NAME_MZ: "Наименование МЗ",
  STORE_CODE: "Склад-код",
  STORE_NAME: "Склад-име",
  STORE_UNIT: "Скл. мярка",
  STORE_QUANTITY: "Количество",
  STORE_PRICE: "Цена",
  STORE_TOTAL: "Отч. стойност",
  STORE_EXPENSES_AVAILABLE: "Наличност",
  STORE_EXPENSES_QUANTITY: "Разход к-во",
  STORE_EXPENSES_DAY: "На ден",
  STORE_EXPENSES_DAYS_LEFT: "Оставащи дни",
  STORE_EXPENSES_WEEKS: "Седмици",
  STORE_EXPENSES_ORDER: "Поръчай",
  DAYS_FOR_ORDER: "Дни за поръчка",
  COUNTERPARTY: "Контрагент",
  IS_FOREIGN: "Чуждестранен?",
  NO_VAT_REASON: "Неначисляване на ДДС",
  PAYMENT_METHOD: "Метод на плащане",
  DUE_DATE: "Падеж",
  PAYMENT_DATE: "Дата на плащане",
  DELIVERY_SLUG: "Начина по който се изписва името в URL (Пример: my-brand)",
  CLEAR: "Изчистване",
  SEARCH: "Търсене",
  STEP: "Стъпка",
  VIEW: "Преглед",
  GIVE: "Даване",
  TAKE: "Вземане",
  AMOUNT: "Сума",
  OPEN_NEW_TAB: "Отвори в нов прозорец",
  ADD_GROUP: "Добави група",
  CHANGE_NAME: "Промени име",
  OVERWRITE_FILTERS: "Презапиши филтри",
  PREPARE: "Подготви",
  ADD_EDIT: "Добавяне/Редактиране",
  OPTIONS: "Опции",
  DOWNLOAD_INCOMING_CONTROL: "Дневник Входящ Контрол",
  DOWNLOAD_PDF: "Свали PDF",
  DOWNLOAD_XLS: "Свали XLS",
  DOWNLOAD_PDF_HYGIENE: "Дневник Хигиенно Състояние",
  DOWNLOAD_PDF_DAILY_TASKS: "Дневни задачи",
  DOWNLOAD_PDF_DISINFECTION_UTENSILS: "Дезинфекция Посуда",
  DOWNLOAD_PDF_DISINFECTION_TOILETS: "Дезинфекция Тоалетни",
  DOWNLOAD_SCRAPPING_PROTOCOL: "Протокол Бракуване",
  DOWNLOAD_PROTOCOL: "Движение Разписка",
  DOWNLOAD_XLS_ANALYTIC_REVERSE_PAY_SHEET: "Аналитична оборотна ведомост",
  DOWNLOAD_XLS_INVENTORY_LOG_DETAILED: "Дневник на мз - Подробен",
  ITEMS: "Артикули",
  DOWNLOAD_ISSUED_DOCUMENTS: "Издадени документи",
  ORDERED: "Поръчани",
  WEIGHT: "Тегло",
  DOUGH_TRAYS: "Дискове",
  DOUGHS: "Теста",
  MIXERS: "Миксери",
  MONDAY: "ПОНЕДЕЛНИК",
  TUESDAY: "ВТОРНИК",
  WEDNESDAY: "СРЯДА",
  THURSDAY: "ЧЕТВЪРТЪК",
  FRIDAY: "ПЕТЪК",
  SATURDAY: "СЪБОТА",
  SUNDAY: "НЕДЕЛЯ",
  DAYS: "ДНИ",
  NO_DATA: "Няма данни",
  BRAND_NO_STORES:
    "Този бранд няма 'магазини'. Може да създадете нов магазин от тук:",
  STORES_HAS_DIFFERENT_PRICE: "Магазин с различна цена",
  IS_SET: "Действието предстои да започне.",
  IS_RUNNING: "Действието все още е в процес на извършване.",
  IS_FINISHED: "Действието е извършено успешно.",
  IS_FAILED: "Действието е извършено неуспешно.",
  IS_CANCELLED: "Действието е прекратено.",
  REFRESH_STATUS:
    "За да проследите дали статуса се е сменил, моля презаредете.",
  CREATE_DELETE_PRODUCT_ACTION:
    "Внимание! Това действие, ще презареди продукта. Ако имате извършени промени, моля първо ги запазете.",
  PROMOTION_CODE_ACTION: "Промо код действия",
  PROMOTION_CODE_ACTION_LOG: "Извършени Промо код действия",
  SAVE_SINGLE_PROMO_CODE:
    "При запазване на новия Промо Код, следният код автоматично става не активен:",
  SENT_TO_X_CUSTOMERS:
    "Изпратено до 0 клиенти | Изпратено до 1 клиент | Изпратено до {x} клиенти",
  SENT_TO_X_GUESTS:
    "Изпратено до 0 гости | Изпратено до 1 гост | Изпратено до {x} гости",
  GENERATED_X_NUMBER_OF_CODES:
    "Генерирани 0 кода | Генериран 1 код | Генерирани {x} кода",
  UNLIMITED_ITEMS_FROM_SLOT_IF_0:
    "При '0' промоцията важи за неограничено количество продукти от слота.",
  FISCAL_REVERSAL: "Сторниране",
  FISCAL_REVERSAL_ORDER: "Сторниране на поръчката",
  FISCAL_REVERSAL_REASON: "Причинa за сторниране",
  FISCAL_REVERSAL_ALREADY_EXISTS: "Поръчката вече е сторнирана",
  FISCAL_REVERSAL_INVALID_EXPIRED:
    "Поръчката не е валидна за сторниране (Изтекъл срок)",
  FISCAL_REVERSAL_INVALID_NO_FISCAL_RECEIPT:
    "Поръчката не е валидна за сторниране (Няма касова бележка)",
  ID: "Номер",
  STOCK_ACTION_TYPE_NAME: "Вид",
  STOCK_INVOICE_TYPE_NAME: "Вид",
  INVOICE_NUMBER: "№ Фактура",
  FOR_DATE: "За дата",
  FROM_DATE: "От дата",
  QUANTITY: "Количество",
  RETURNING: "Връщане",
  ADJUSTMENT_SUPPLIER: "Корекция от доставчик (зануляване)",
  ADJUSTMENT_WHOLESALE: "Корекция продажба (зануляване)",
  RECEIVE: "Получаване",
  SINGLE_PRICE: "Ед. цена",
  SINGLE_PRICE_WHOLESALE: "Продажна Ед. цена",
  TOTAL_PRICE_WHOLESALE: "Продажна Обща цена",
  SINGLE_PRICE_CURRENCY_WHOLESALE: "Продажна Ед. цена Валута",
  TOTAL_PRICE_CURRENCY_WHOLESALE: "Продажна Обща цена Валута",
  TRANSPORT_PRICE: "Цена транспорт",
  PALLET_PRICE: "Цена палет",
  CUSTOMS_PRICE: "Цена мито",
  DOC_DATE: "Док. Дата",
  DELIVERY_NOTE_DOC_NUM: "Стокова Номер",
  DELIVERY_NOTE_DOC_DATE: "Стокова Дата",
  INVOICE_DOC_NUM: "Фактура Номер",
  INVOICE_DOC_DATE: "Фактура Дата",
  SUPPLIER_DOC_NUM: "Доставчик Док Номер",
  SUPPLIER_DOC_DATE: "Доставчик Док Дата",
  WHOLESALE_DOC_NUM: "Продажба Док Номер",
  WHOLESALE_DOC_DATE: "Продажба Док Дата",
  TRANSPORT_DOC_NUM: "Транспорт Док Номер",
  TRANSPORT_DOC_DATE: "Транспорт Док Дата",
  PALLET_DOC_NUM: "Палет Док Номер",
  PALLET_DOC_DATE: "Палет Док Дата",
  CUSTOMS_DOC_NUM: "Мито Док Номер",
  CUSTOMS_DOC_DATE: "Мито Док Дата",
  SCRAPPING: "Бракуване",
  TESTING: "За тестове",
  BATCH: "Партида",
  EXP_DATE: "Срок на годност",
  SCRAPPING_REASON: "Причина за бракуване",
  SCRAPPING_TYPE: "Начин на бракуване",
  TESTING_REASON: "Причина за тестване",
  TOTAL_VALUE: "Обща стойност",
  LAST_STOCK_AVERAGE_COST: "Последна средна стойност",
  LOT: "LOT",
  LOT_EXP_DATE: "LOT EXP",
  PRODUCTION: "Производство",
  STOCK_PURCHASE_ORDER_STATUS: "Статус",
  STOCK_PURCHASE_ORDER_REQUESTED_UNIT_VALUE: "Заявено количество",
  STOCK_PURCHASE_ORDER_READY_TO_SEND: "Изпраща се количество",
  STOCK_PURCHASE_ORDER_READY_TO_SEND_FROM_LOT:
    "Изпраща се количество от партида",
  STOCK_PURCHASE_ORDER_IS_REQUESTED: "Заявен?",
  LOTS: "Партиди",
  IN_STOCK: "Налично количество",
  OTHER_BOOKED_STOCK: "Запазено",
  AVAILABLE_FOR_BOOKING_STOCK: "Оставащо количество",
  REDIRECTING: "Пренасочени",
  NOT_ENOUGH_STOCK: "Недостиг на количество",
  RECEIVER: "Получател",
  SENDER: "Изпращач",
  USER_REQUESTED: "Заявено от",
  ACTION_RECEIVING: "Получи",
  SOLVE_DISCREPANCY: "Разреши несъответсвията",
  SENT: "Изпратени",
  RECEIVED: "Получени",
  RETURNED: "Върнати",
  MISSED: "Липсващи",
  IN_SENDER: "При Изпращача",
  IN_RECEIVER: "При Получателя",
  EXPORT_STOCK_REDIRECTING_XLS: "Свали стокова разписка",
  CURRENCY: "Валута",
  VAT_GROUPS: "ДДС %",
  TRANSPORT: "Транспорт",
  PALLET: "Палет",
  CUSTOMS_OFFICE: "Митница",
  VAT: "ДДС",
  TOTAL_WITH_VAT: "Общо с ДДС",
  TOTAL_BGN: "Общо (в BGN)",
  VAT_BGN: "ДДС (в BGN)",
  TOTAL_WITH_VAT_BGN: "Общо с ДДС (в BGN)",
  HAS_INVOICE: "С Фактура",
  HAS_INVOICE_SUPPLIER: "С Фактура Доставчик",
  HAS_INVOICE_WHOLESALE: "С Фактура Продажба",
  HAS_DELIVERY_NOTE: "Със Стокова Разписка",
  HAS_SUPPLIER: "С Доставчик",
  HAS_WHOLESALE: "С Продажба",
  HAS_TRANSPORT: "С Транспорт",
  HAS_PALLET: "С Палет",
  HAS_INVOICE_TRANSPORT: "С Фактура Транспорт",
  HAS_INVOICE_PALLET: "С Фактура Палет",
  HAS_CUSTOMS: "С Мито",
  HAS_INVOICE_CUSTOMS: "С Фактура Мито",
  DOCS: "Документи",
  REG_NUM: "Рег. номер",
  REG_DESCRIPTION: "Рег. описание",
  REG_DATE: "Рег. дата",
  BUTTON_ACCOUNT: "Осчетоводи",
  BUTTON_RE_ACCOUNT: "Редактирай",
  WHOLESALE: "Продажба",
  WHOLESALE_CLIENT_STORES: "Обекти на клиент",
  TOTAL_DOUGH_TRAYS_VALUE: "Общо Миксери",
  LAST_STOCK: "Предишна инвентар.",
  CHANGED_STOCK: "Промяна к-во",
  INITIAL_STOCK: "Начално к-во",
  ORDERED_UNIT_VALUE: "Продажби",
  CONSUMED_UNIT_VALUE: "Разход",
  IDEAL_STOCK: "Идеална налич.",
  CURRENT_STOCK: "Реална налич.",
  CONSUMPTION: "Реално потреб.",
  STOCK_DIFFERENCE: "Разлика налич.",
  AUDIT_FOOD_COST: "Стойност потреб.",
  MISSING_PRODUCTS: "Няма избрани продукти",
  USER_DISCOUNT: "Намаление",
  ORDER_FINISHED: "Поръчката е звършена успешно",
  ORDER_PHONE_REQUEST_HEADING: "Телефонен номер",
  ORDER_MANAGER_PASS_CODE_REQUEST_HEADING:
    "Поръчката има отстъпка над 50%. Моля въведете код на мениджър",
  ADDRESSES_LIST_HEADING: "Адреси за номер",
  GUEST_ADDRESSES_LIST_HEADING: "Гости",
  ADDRESS_EDIT_HEADING: "Адрес:",
  STORE_CLOSED_HEADING: "Избраната локация е затворена",
  PRICE_HEADING: "Цена",
  TOTAL_LABEL_NO_DELIVERY: "Общо поръчки:",
  TOTAL_LABEL: "Общо:",
  STORE_CLOSED: "Затворен",
  EXTRA: "Допълнително",
  CARRY_OUT_DONE: "Предаване на клиент",
  CARRY_OUT_CASH_PAYMENT: "Плащане в брой и предаване на клиент",
  CARRY_OUT_POS_PAYMENT: "Плащане на ПОС и предаване на клиент",
  DELIVERING: "Доставка",
  MANAGEMENT: "Мениджър",
  DRIVERS: "Шофьори",
  FISCAL_REFRESH: "Опитай отново",
  FISCAL_RESET: "Рестартирай поръчката",
  FISCAL_COPY: "Копие на последна бележка",
  FISCAL_PRINTED: "Бележката е отпечатана",
  NEW_ORDERS: "Нови поръчки",
  EARLIER_ORDERS: "По-ранни поръчки",
  CHANGE_DRIVER: "Смяна на шофьор",
  MANAGER_NOTES_CANCEL: "Причина за Cancel на поръчката",
  CLOCK_TRACKS: "Работни Часове:",
  TOTAL_HOURS: "Общо Часове:",
  DRIVER_NAME: "Име на шофьора",
  COUNT_ORDERS_FOR_DRIVER: "Брой поръчки",
  SUM_TOTAL_DRIVER_WALLET: "Общо пари",
  OLD_DRIVER: "Предишен шофьор",
  CLOCK_OUT_AND_TRANSFER_MONEY: "Clock Out and Transfer Money",
  HAS_UNACCOUNTED_MONEY: "има неотчетени пари",
  HAS_НОТ_UNACCOUNTED_MONEY: " отчел всички пари",
  MONEY_ARE_ACCOUNTED_AND_CLOCK_OUT: "Отчитам парите и отписвам",
  CLOCKING_OUT: "Отписвам",
  FISCAL_PRINTED_AT: "Касова бележка отпечатана в:",
  FISCAL_CASH_DESK: "Каса име:",
  X_REPORT: "X отчет",
  Z_REPORT: "Z отчет Нулира касата",
  FISCAL_CASH_DESK_TRACK_TYPES: "Типове движения",
  OPEN_DIALOG_WITH_CHART: "Отвори диаграми за: {name}",
  PRODUCTS_BY_CATEGORY_CHARTS: "Диаграми по продукти от Категория",
  CATEGORY_CHARTS: "Диаграми по Категория",
  ITEM_CATEGORY_BY_BRAND_CHART: "{itemCategoryName} по Бранд",
  ITEM_CATEGORY_BY_STORE_CHART: "{itemCategoryName} по Магазин",
  PERCENT_PRICE: "% от продажби (цена)",
  PERCENT_QTY: "% от продажби (количество)",
  PERCENT_CATEGORY_PRICE: "% от категория {itemCategoryName} (цена)",
  PERCENT_CATEGORY_QTY: "% от категория {itemCategoryName} (количество)",
  PRICE_CHART: "Цена",
  QTY_CHART: "Количество",
  TOTAL_QTY: "Бройки",
  TOTAL_MAX_QTY: "Макс Бройки в поръчка",
  TOTAL_PRICE_NO_VAT: "Общо без ДДС",
  TOTAL_PERCENT_OF_CATEGORY_SALES: "% от {category}",
  TOTAL_PERCENT_OF_CATEGORY_SALES_LOCAL: "% от {category} локално",
  TOTAL_PERCENT_OF_SALES: "% от Продажбите",
  COUNT_EMPLOYEES_AT_WORK: "Общо служители на работа",
  SUM_WORKED_HOURS: "Общо работни часове",
  SUM_LABOR_COST: "Общо LC",
  SUM_TOTAL_SALES_NO_VAT: "Общо продажби (без ДДС)",
  SUM_IDEAL_FOOD_COST: "Общо Ideal FC",
  SUM_REAL_FOOD_COST: "Общо Real FC",
  MONTH: "Година-Месец",
  DAY: "Ден",
  EMPLOYES_AT_WORK: "Служители на работа",
  WORKED_HOURS: "Работни часове",
  LABOR_COST: "LC",
  TOTAL_SALES_NO_VAT: "Продажби (без ДДС)",
  IDEAL_FOOD_COST: "Ideal FC",
  REAL_FOOD_COST: "Real FC",
  LC_PERCENT: "LC %",
  IFC_PERCENT: "I FC %",
  RFC_PERCENT: "R FC %",
  FC_PERCENT: "FC %",
  ORDERS_WITH_CANCELLED: "С Канселирани Поръчки:",
  CARRY_OUT_DELIVERY: "На място/Доставка:",
  LUNCH_DINNER: "Обяд/Вечер:",
  LUNCH: "Обяд",
  DINNER: "Вечер",
  ORDER_SOURCE: "Поръчка направена от:",
  ORDER_PAYMENT_METHOD: "Поръчка платена със:",
  WEBSITE: "Website",
  MOBILE_APP: "Mobile App",
  KIOSK: "На каса",
  CALL_CENTER: "Call Center",
  CASH: "В брой",
  CARD: "С Карта онлайн",
  POS: "ПОС терминал",
  BANK: "Банка",
  NET: "без ДДС",
  GROSS: "с ДДС",
  AVG_TICKET: "Средна сума",
  TOTAL_SALES: "Общите продажби (с ДДС)",
  TOTAL_DISCOUNTS: "Общо отстъпки",
  TOTAL_USER_DISCOUNTS: "Общо служебни отстъпки",
  TOTAL_SALES_NO_DELIVERY: "Общите продажби без Доставка (с ДДС)",
  TOTAL_SALES_NO_VAT_NO_DELIVERY: "Общите продажби без Доставка (без ДДС)",
  TOTAL_DISCOUNTS_NO_DELIVERY: "Общо отстъпки без Доставка",
  ORDERS_TOTAL_DELIVERY_PRICE: "Общо Цена за доставки",
  ORDERS_TOTAL_DELIVERY_DISCOUNT_VALUE: "Общо Намаления на доставки",
  ORDERS_TOTAL_DISCOUNTS_WITH_DELIVERY: "Общо отстъпки с Доставка",
  ORDERS_TOTAL_SALES_WITH_DELIVERY: "Общите продажби с Доставка (с ДДС)",
  ORDERS_TOTAL_SALES_NO_VAT_WITH_DELIVERY:
    "Общите продажби с Доставка (без ДДС)",
  TOTAL_SALES_CANCELLED: "Общите продажби (с ДДС) (к)",
  TOTAL_SALES_NO_VAT_CANCELLED: "Общите продажби (без ДДС) (к)",
  TOTAL_DISCOUNTS_CANCELLED: "Общо отстъпки (к)",
  TOTAL_USER_DISCOUNTS_CANCELLED: "Общо Служебни отстъпки (к)",
  COUNT_ORDERS_WITHOUT_CANCELLED: "Финализирани поръчки без канселирани",
  COUNT_FINISHED_ORDERS: "Финализирани поръчки",
  COUNT_IN_PROGRESS_ORDERS: "Поръчки в прогрес",
  SUM_CANCELLED_ORDERS_WITH_RECEIPT: "Канселирани поръчки с КБ (B)",
  SUM_CANCELLED_ORDERS_WITHOUT_RECEIPT: "Канселирани поръчки преди КБ (V)",
  COUNT_CANCELLED_ORDERS_WITH_RECEIPT: "Канселирани поръчки с КБ (B)",
  COUNT_CANCELLED_ORDERS_WITHOUT_RECEIPT: "Канселирани поръчки преди КБ (V)",
  ORDER_INFORMATION: "Информация за поръчката",
  DELIVERY_ADDRESS: "Адрес за доставка",
  CUSTOMER_GROUPS_ADD: "Добавяне на клиентска група",
  CUSTOMER_GROUPS_EDIT: "Редакция на клиентска група",
  CLOCK_IN: "Clock In",
  CLOCK_OUT: "Clock Out",
  ORDERS_CLOCK_IN_CONFIRM: "Потвърждавам Clock In в поръчки:",
  ORDERS_DELETE_CONFIRM: "Потвърждавам изтриване на поръчка:",
  DRIVER_ACTIVE: "Шофьор Активен/Неактивен",
  SELECTED_RECORDS_COUNT: "Selected records count: ",
  SUSPENDED: "Suspended",
  FILTER: "Filter",
  BY_STATUS: "by Status",
  BY_TYPE: "by Type",
  BUSINESS: "Business",
  INDIVIDUAL: "Individual",
  IN_ALL_FIELDS: "in all fields",
  ECOMMERCE: "eCommerce",
  CUSTOMERS_LIST: "Customers list",
  NEW_CUSTOMER: "New Customer",
  CUSTOMER_DELETE_TITLE: "Customer Delete",
  CUSTOMER_DELETE_DESCRIPTION:
    "Are you sure to permanently delete this customer?",
  CUSTOMER_DELETE_WAIT_DESCRIPTION: "Customer is deleting...",
  CUSTOMER_DELETE_MESSAGE: "Customer has been deleted",
  DELETE_CUSTOMER_MULTY_TITLE: "Customers Delete",
  DELETE_CUSTOMER_MULTY_DESCRIPTION:
    "Are you sure to permanently delete selected customers?",
  DELETE_CUSTOMER_MULTY_WAIT_DESCRIPTION: "Customers are deleting...",
  DELETE_CUSTOMER_MULTY_MESSAGE: "Selected customers have been deleted",
  CUSTOMER_UPDATE_STATUS_TITLE:
    "Status has been updated for selected customers",
  CUSTOMER_UPDATE_STATUS_MESSAGE:
    "Selected customers status have successfully been updated",
  CUSTOMER_EDIT_UPDATE_MESSAGE: "Customer has been updated",
  CUSTOMER_EDIT_ADD_MESSAGE: "Customer has been created",
  CURRENCY_CODE: "Код валута",
  DOC_ID: "ID",
  DOC_NUM: "Документ №",
  DOC_TOTAL_WITH_VAT_CURRENCY: "Документ сума с ДДС (валута)",
  SUPPLIER_NAME: "Доставчик",
  TOTAL_WITHOUT_VAT: "Общо без ДДС",
  TOTAL_WITHOUT_VAT_CURRENCY: "Общо без ДДС (валута)",
  TOTAL_VAT: "Общо ДДС",
  TOTAL_VAT_CURRENCY: "Общо ДДС (валута)",
  TOTAL_WITH_VAT_CURRENCY: "Общо с ДДС (валута)",
  ADD_TO_INVOICE: "Добави към фактура",
  CLOSE_INVOICE: "Затвори фактура",
  ITEM_NAME: "Име на артикул",
  SINGLE_PRICE_CURRENCY: "Единична цена (валута)",
  TOTAL_PRICE_CURRENCY: "Обща цена (валута)",
  INVOICE: "Фактура",
  NEW_INVOICE: "Нова фактура",
  EXISTING_INVOICE: "Съществуваща фактура",
  INTERNAL: "Вътрешна",
  ЕXTERNAL: "Външна",
  ISSUE_DATE: "Дата на издаване",
  USE: "Използвай",
  OPEN: "Отвори",
  ACCOUNTED: "Осчетоводена",
  ISSUER_DATA: "Данни на издателя",
  RECIPIENT_DATA: "Данни на получателя",
  COMPANY_OWNER: "МОЛ",
  COMPANY_ADDRESS: "Адрес",
  COMPANY_NAME: "Име на фирма",
  ITEMS_LIST: "Списък с артикули",
  SERVICES: "Услуги",
  TOTAL_CURRENCY: "Общо (валута)",
  STOCK_ACTION_NUMBER: "Движение №",
  NAME_LOT: "Име/Партида",
  QTY: "Количество",
  UNIT_VALUE_TYPE: "Мерна ед.",
  TOTAL_PRICE: "Обща цена",
  EXCHANGE_RATE: "Курс",
  TOTAL_PRICE_BGN: "Обща цена (BGN)",
  REG_AT: "Рег. на",
  BUTTON_EDIT_ACCOUNT: "Редактирай осчетоводяване",
  CURRENCY_LABEL: "Валута",
  PALLETS: "Палети",
  DOCUMENTS: "Документи",
  SHOW: "Покажи",
  HIDE: "Скрий",
  FORWARDER_NAME: "Име на транспортна фирма",
  CONTRACTOR_NAME: "Име на изпълнителна фирма",
  TRANSPORTS: "Транспорти",
  WHOLESALES: "Продажби",
  CLIENT_NAME: "Име на клиент",
  PURCHASES: "Покупки",
  BUTTON_UPDATE_DATA: "Запазване на данни",
  NET_WEIGHT: "Нетно тегло",
  GROSS_WEIGHT: "Брутно тегло",
  ECHO_INVOICE_TO_AJUR: "Send invoices to Ajur",
  DOWNLOAD_TOTAL_EXCEL: "Download Total",
  TOTAL_WAREHOUSE_PRICE: "Обща отчетна цена",
  ADDITIONAL_SERVICE: "Допълнителна услуга",
  ADDITIONAL_SERVICE_COST: "Цена на услугата",
  AJUR_NUMBER_LIST: "Лист по Ажур номера",
  STOCK_CONSUMPTIONS_TOTAL: "Общо (cost)",
  MATERIALI_TOTAL: "Материали Общo",
  POLUFABRIKATI_TOTAL: "Полуфабрикати Общo",
  STOKI_TOTAL: "Стоки Общo",
  DOWNLOAD_INVOICES_EXCEL: "Свали Фактури",
  DOWNLOAD_ITEMS_PRICE_EXCEL: "Справка цени"
};
