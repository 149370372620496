import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
//Setting Pages
export const FETCH_SETTINGS = "fetchSettings";
export const FETCH_SETTING = "fetchSetting";
export const FETCH_SETTING_BY_SLUG = "fetchSettingBySlug";
export const SAVE_SETTING = "saveSetting";
export const UPDATE_SETTING = "updateSetting";
export const UPDATE_SETTING_BY_SLUG = "updateSettingBySlug";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingSetting";
export const SET_SETTINGS = "setSettings";

const state = {
  errors: [],
  settings: [],
  isLoadingSetting: false
};

const getters = {
  isLoadingSetting() {
    return state.isLoadingSetting;
  },
  getSettings() {
    return state.settings;
  }
};

const actions = {
  [FETCH_SETTINGS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("settings" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SETTING](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("settings", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SETTING_BY_SLUG](context, slug) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("settings-by-slug", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SETTING](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("settings", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SETTING](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("settings", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SETTING_BY_SLUG](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const slug = params.slug;
    return new Promise((resolve, reject) => {
      ApiService.update("settings-by-slug", slug, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingSetting = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingSetting = value;
  },
  [SET_SETTINGS](state, data) {
    state.isLoadingSetting = false;
    state.settings = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
