import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_IMAGE_TYPES = "fetchImageTypes";

//Mutations
export const SET_IMAGE_TYPES = "setImageTypes";

const state = {
  imageTypes: null
};

const getters = {
  getImageTypes() {
    return state.imageTypes;
  }
};

const actions = {
  [FETCH_IMAGE_TYPES]({ commit, state }) {
    if (!state.imageTypes) {
      return new Promise((resolve, reject) => {
        ApiService.get("images/image-types")
          .then(({ data }) => {
            commit(SET_IMAGE_TYPES, data.data);

            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  }
};

const mutations = {
  [SET_IMAGE_TYPES](state, data) {
    state.imageTypes = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
