import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
import AuthService from "./core/services/auth.service";
// import NotifyService from "./core/services/notify.service";

//Websockets
// import Echo from "laravel-echo";

// window.Pusher = require("pusher-js");

// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_API_PUSHER_KEY,
//   wsHost: process.env.VUE_APP_API_HOSTNAME,
//   wsPort: 6001,
//   wssPort: 6001,
//   disableStats: false,
//   enabledTransports: ["ws", "wss"]
// });
//Websockets

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
// Load the full build.
import VueLodash from "vue-lodash";
import lodash from "lodash";
import Notifications from "vue-notification";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import DatetimePicker from "vuetify-datetime-picker";
Vue.use(DatetimePicker);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

import VuejsDialog from "vuejs-dialog";
// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyALaV2jHHdGLo0p3jnXs8Rm-Yw2l_FDPSI",
    libraries: "places" // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

// name is optional
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.use(Notifications);
Vue.use(VuejsDialog, {
  html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
  loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
  reverse: false, // switch the button positions (left to right, and vise versa)
  okText: i18n.t("CONFIRM"),
  cancelText: i18n.t("CLOSE"),
  animation: "bounce", // Available: "zoom", "bounce", "fade"
  type: "basic", // coming soon: 'soft', 'hard'
  verification: "REMOVE", // for hard confirm, item will be prompted to type this to enable the proceed button
  clicksCount: 3 // for soft confirm, item will be asked to click on "proceed" btn 3 times before actually proceeding
});
Vue.component("pulse-loader", PulseLoader);

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// Vue custom directives
import "./core/directives/v-permission";
import BackButton from "./core/components/BackButton.vue";
import DeleteButton from "./core/components/DeleteButton.vue";
import SkeletonLoaderMain from "./core/components/skeleton/SkeletonLoaderMain.vue";
import SkeletonLoaderCheckboxes from "./core/components/skeleton/SkeletonLoaderCheckboxes.vue";

// Vue custom filters
import "./core/filters/filters";

Vue.component("BackButton", BackButton);
Vue.component("DeleteButton", DeleteButton);
Vue.component("SkeletonLoaderMain", SkeletonLoaderMain);
Vue.component("SkeletonLoaderCheckboxes", SkeletonLoaderCheckboxes);

// import plugin
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md"
});
// API service init
ApiService.init();
AuthService.init().then(() => {
  //RefreshUserData every 1 minute on loop, not using websockets yet 16.02.2023 Anzhel
  window.setInterval(() => {
    AuthService.refreshUserData();
  }, 60000);
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App)
  }).$mount("#app");
  // NotifyService.init();
});
// Your web app's Firebase configuration // For Firebase JS SDK v7.20.0 and later, measurementId is optional
