import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions ITEMS
export const FETCH_ITEMS = "fetchItems";
export const FETCH_ALL_ITEMS = "fetchAllItems";
export const FETCH_ALL_ITEMS_CLIENT_PRICE = "fetchAllItemsClientPrice";
export const FETCH_ALL_ITEMS_MULTIPLE = "fetchAllItemsMultiple";
export const FETCH_INGREDIENTS = "fetchIngredients";
export const FETCH_ITEM = "fetchItem";
export const SAVE_ITEM = "saveItem";
export const DUPLICATE_ITEM = "duplicateItem";
export const UPDATE_ITEM = "updateItem";
export const UPDATE_SINGLE_ITEM = "updateSingleItem";
export const DELETE_ITEM = "deleteItem";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingItem";
export const SET_ITEMS = "setItems";

const state = {
  errors: [],
  items: [],
  isLoadingItem: false
};

const getters = {
  isLoadingItem() {
    return state.isLoadingItem;
  },
  getItems() {
    return state.items;
  }
};

const actions = {
  [FETCH_ITEMS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ALL_ITEMS_CLIENT_PRICE](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("get-all-items-client-price" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ALL_ITEMS_MULTIPLE](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("get-all-items-multiple")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ALL_ITEMS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("get-all-items")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_INGREDIENTS](context, apiParams = "") {
    // context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("get-ingredients" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          // context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ITEM](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("items", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ITEM](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("items", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DUPLICATE_ITEM](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("duplicate-item", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ITEM](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("items", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SINGLE_ITEM](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("update-single-item", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ITEM](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("items", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingItem = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingItem = value;
  },
  [SET_ITEMS](state, data) {
    state.isLoadingItem = false;
    state.items = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
