//Core
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

//Module Modules
import shared_file from "./shared_file.module";
import shared_store from "./shared_store.module";
import shared_supplier from "./shared_supplier.module";
import shared_base from "./shared_base.module";
import shared_user from "./shared_user.module";
import shared_mail from "./shared_mail.module";
import shared_notify from "./shared_notify.module";

export default {
  auth,
  htmlClass,
  config,
  breadcrumbs,
  shared_file,
  shared_store,
  shared_supplier,
  shared_base,
  shared_user,
  shared_mail,
  shared_notify
};
