import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_STORE_BASIC = "fetchStoreBasic";
export const FETCH_STORES_BASIC = "fetchStoresBasic";
export const FETCH_STORES_WITH_SUPPLIERS = "fetchStoresWithSuppliers";
export const FETCH_STORES_WAREHOUSES = "fetchStoresWarehouses";

//Mutations
export const SET_LOADING = "setLoadingSharedStore";

const state = {
  isLoadingSharedStore: false
};

const getters = {
  isLoadingSharedStore() {
    return state.isLoadingSharedStore;
  }
};

const actions = {
  [FETCH_STORE_BASIC](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("store-basic", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STORES_BASIC](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stores-get-all-basic" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STORES_WITH_SUPPLIERS](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stores-get-all-with-suppliers" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STORES_WAREHOUSES](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("stores-warehouses-get-all" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedStore = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
