import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { API_URL } from "@/core/config/config.js";
import i18nService from "./i18n.service";

/**
 * Check if access token has expired before request and refresh it
 */

/*
НАДОЛУ (ref:1daSfDl210) Откоментирай, ако ще ползваме интерсептор за CheckToken на всяка заявка, за да рефрешнем токена с рефреш токена и да не губим workflow*/

// import store from "../services/store";
// import router from "../../router";
// import { CHECK_TOKEN } from "@/core/services/store/auth.module";
// import { PURGE_AUTH } from "@/core/services/store/auth.module";

// const createAxiosResponseInterceptor = () => {
//   const interceptor = axios.interceptors.response.use(
//     response => response,
//     error => {
//       // Reject promise if usual error
//       if (
//         error.response.status !== 401 ||
//         error.response.config.url === "refresh"
//       ) {
//         return Promise.reject(error);
//       }

//       /*
//        * When response code is 401, try to refresh the token.
//        * Eject the interceptor so it doesn't loop in case
//        * token refresh causes the 401 response
//        */
//       axios.interceptors.response.eject(interceptor);

//       return store
//         .dispatch(CHECK_TOKEN)
//         .then(data => {
//           console.log(error.response.config.url);
//           console.log("then");
//           console.log(data);
//           error.response.config.headers["Authorization"] =
//             "Bearer " + data.data.access_token;

//           console.log(error.response.config);
//           return axios(error.response.config);
//         })
//         .catch(error => {
//           console.log("redirect to login");
//           console.log(error);

//           store.commit(PURGE_AUTH);
//           router.push({ name: "login" });
//           // JwtService.destroyTokens();
//           // router.push({ name: "login" });
//           return Promise.reject(error);
//         })
//         .finally(createAxiosResponseInterceptor);
//     }
//   );
// };

/*
НАГОРЕ (ref:1daSfDl210) Откоментирай, ако ще ползваме интерсептор за CheckToken на всяка заявка, за да рефрешнем токена с рефреш токена и да не губим workflow*/

/**
 * Set the default HTTP request headers
 */
const setHeader = () => {
  axios.interceptors.request.use((config) => {
    let token = JwtService.getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    let language = i18nService.getActiveLanguage();
    if (language) {
      config.headers["Accept-Language"] = `${language}`;
    } else {
      config.headers["Accept-Language"] = `en`;
    }

    config.headers["App-Type"] = `web`;

    return config;
  });
};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    setHeader();
    // createAxiosResponseInterceptor();
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  getPDF(resource) {
    return Vue.axios
      .get(resource, { responseType: "arraybuffer" })
      .catch((error) => {
        // console.log(error);
        throw new Error(`PDF ${error}`);
      });
  },

  getXML(resource) {
    return Vue.axios.get(resource, { responseType: "arraybuffer" });
  },

  postXML(resource, params) {
    return Vue.axios.post(resource, params, { responseType: "arraybuffer" });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    let url = "";
    if (slug != "") {
      url = `${resource}/${slug}`;
    } else {
      url = `${resource}`;
    }
    return Vue.axios.get(url);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, options = {}) {
    return Vue.axios.post(`${resource}`, params, options);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug) {
    let url = resource;

    if (slug) {
      url += "/" + slug;
    }

    return Vue.axios.delete(url).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
