export default [
  {
    path: "/users-schedule",
    name: "users-schedule",
    component: () => import("@/modules/users-schedule/UsersSchedule.vue"),
    children: [
      {
        path: "list",
        name: "list-users-schedule",
        component: () =>
          import("@/modules/users-schedule/pages/UsersScheduleList.vue"),
        meta: { permissions: ["users-show"] }
      }
    ]
  }
];
