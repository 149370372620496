import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
//Actions
export const FETCH_DAILY_TASKS = "fetchDailyTasks";
export const SAVE_DAILY_TASK = "saveDailyTask";
export const UPDATE_DAILY_TASK = "updateDailyTask";
export const DELETE_DAILY_TASK = "deleteDailyTask";

export const FETCH_STORE_DAILY_TASKS = "fetchStoreDailyTasks";
export const UPDATE_STORE_DAILY_TASK = "updateStoreDailyTask";
export const GET_STORE_DAILY_TASKS_DASHBOARD = "getStoreDailyTask";
export const GET_STORE_DAILY_TASKS_PDF = "getStoreDailyTaskPDF";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingDailyTasks";
export const SET_LOADING_STORE_DAILY_TASKS = "setLoadingStoreDailyTask";
export const SET_DAILY_TASKS = "setDailyTasks";
export const SET_STORE_DAILY_TASK = "setStoreDailyTask";

const state = {
  errors: [],
  dailyTasks: [],
  storeDailyTasks: [],
  isLoadingDailyTasks: false,
  isLoadingStoreDailyTasks: false
};

const getters = {
  isLoadingDailyTasks() {
    return state.isLoadingDailyTasks;
  },
  getDailyTasks() {
    return state.dailyTasks;
  },
  isLoadingStoreDailyTasks() {
    return state.isLoadingStoreDailyTasks;
  },
  getStoreDailyTasks() {
    return state.storeDailyTasks;
  }
};

const actions = {
  [FETCH_DAILY_TASKS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("daily-tasks" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_DAILY_TASK](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("daily-tasks", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_DAILY_TASK](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("daily-tasks", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_DAILY_TASK](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.delete("daily-tasks", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [FETCH_STORE_DAILY_TASKS](context, apiParams = "") {
    context.commit(SET_LOADING_STORE_DAILY_TASKS, true);

    return new Promise((resolve, reject) => {
      ApiService.get("store-tasks" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_DAILY_TASKS, false);
        });
    });
  },
  [UPDATE_STORE_DAILY_TASK](context, params) {
    context.commit(SET_LOADING_STORE_DAILY_TASKS, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("store-tasks", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_DAILY_TASKS, false);
        });
    });
  },
  [GET_STORE_DAILY_TASKS_DASHBOARD](context, apiParams = "") {
    context.commit(SET_LOADING_STORE_DAILY_TASKS, true);
    return new Promise((resolve, reject) => {
      ApiService.get("tasks-on-dashboard" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_DAILY_TASKS, false);
        });
    });
  },
  [GET_STORE_DAILY_TASKS_PDF](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getPDF("store-tasks-create-pdf" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingDailyTasks = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingDailyTasks = value;
  },
  [SET_DAILY_TASKS](state, data) {
    state.isLoadingDailyTasks = false;
    state.dailyTasks = data.data;
  },
  [SET_LOADING_STORE_DAILY_TASKS](state, value) {
    state.isLoadingStoreDailyTasks = value;
  },
  [SET_STORE_DAILY_TASK](state, data) {
    state.isLoadingStoreDailyTasks = false;
    state.storeDailyTasks = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
