import ApiService from "@/core/services/api.service";
import {
  GET_KITCHEN_EQUIPMENT_NAME,
  GET_KITCHEN_EQUIPMENT_TEMPERATURE_PDF,
  SET_LOADING_TEMPERATURES
} from "@/modules/kitchen-equipments/store/kitchen-equipments.module";
// import JwtService from "@/core/services/jwt.service";

//Actions
//Actions
export const FETCH_FOOD_TESTS = "fetchFoodTests";
export const SAVE_FOOD_TEST = "saveFoodTest";
export const UPDATE_FOOD_TEST = "updateFoodTest";
export const DELETE_FOOD_TEST = "deleteFoodTest";

export const FETCH_STORE_FOOD_TESTS = "fetchStoreFoodTests";
export const STORE_FOOD_TESTS_TEMPERATURE_PDF = "storeStoreFoodTestsPDF";
export const UPDATE_STORE_FOOD_TEST = "updateStoreFoodTest";
export const SAVE_STORE_FOOD_TEST = "saveStoreFoodTest";
export const DELETE_STORE_FOOD_TEST = "deleteStoreFoodTest";
export const GET_FOOD_SELECTS = "getFoodSelects";
//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingFoodTests";
export const SET_LOADING_STORE_FOOD_TESTS = "setLoadingStoreFoodTest";
export const SET_FOOD_TESTS = "setFoodTests";
export const SET_STORE_FOOD_TEST = "setStoreFoodTest";

const state = {
  errors: [],
  foodTests: [],
  storeFoodTests: [],
  isLoadingFoodTests: false,
  isLoadingStoreFoodTests: false
};

const getters = {
  isLoadingFoodTests() {
    return state.isLoadingFoodTests;
  },
  getFoodTests() {
    return state.foodTests;
  },
  isLoadingStoreFoodTests() {
    return state.isLoadingStoreFoodTests;
  },
  getStoreFoodTests() {
    return state.storeFoodTests;
  }
};

const actions = {
  [FETCH_FOOD_TESTS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("food-testing" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_FOOD_TEST](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("food-testing", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_FOOD_TEST](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("food-testing", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_FOOD_TEST](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.delete("food-testing", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [FETCH_STORE_FOOD_TESTS](context, apiParams = "") {
    context.commit(SET_LOADING_STORE_FOOD_TESTS, true);

    return new Promise((resolve, reject) => {
      ApiService.get("store-food-tests" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_FOOD_TESTS, false);
        });
    });
  },
  [UPDATE_STORE_FOOD_TEST](context, params) {
    context.commit(SET_LOADING_STORE_FOOD_TESTS, true);
    const form = params.payload;
    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("store-food-tests", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_FOOD_TESTS, false);
        });
    });
  },
  [SAVE_STORE_FOOD_TEST](context, payload) {
    context.commit(SET_LOADING_STORE_FOOD_TESTS, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("store-food-tests", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_FOOD_TESTS, false);
        });
    });
  },
  [DELETE_STORE_FOOD_TEST](context, payload) {
    context.commit(SET_LOADING_STORE_FOOD_TESTS, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.delete("store-food-tests", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_FOOD_TESTS, false);
        });
    });
  },
  [GET_FOOD_SELECTS](context) {
    context.commit(SET_LOADING_STORE_FOOD_TESTS, true);
    return new Promise((resolve, reject) => {
      ApiService.get("get-food-selects")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING_STORE_FOOD_TESTS, false);
        });
    });
  },
  [STORE_FOOD_TESTS_TEMPERATURE_PDF](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.getPDF("store-food-tests-create-pdf" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingFoodTests = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingFoodTests = value;
  },
  [SET_FOOD_TESTS](state, data) {
    state.isLoadingFoodTests = false;
    state.foodTests = data.data;
  },
  [SET_LOADING_STORE_FOOD_TESTS](state, value) {
    state.isLoadingStoreFoodTests = value;
  },
  [SET_STORE_FOOD_TEST](state, data) {
    state.isLoadingStoreFoodTests = false;
    state.storeFoodTests = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
