export default [
  {
    path: "/kitchen-equipments",
    name: "kitchen-equipments",
    component: () =>
      import("@/modules/kitchen-equipments/KitchenEquipments.vue"),
    children: [
      {
        path: "list",
        name: "list-kitchen-equipments",
        component: () =>
          import(
            "@/modules/kitchen-equipments/pages/KitchenEquipmentsList.vue"
          ),
        meta: { permissions: ["stores-show"] }
      },
      {
        path: "temperature/:id",
        name: "temperature-kitchen-equipments",
        component: () =>
          import(
            "@/modules/kitchen-equipments/pages/KitchenEquipmentsTemperature.vue"
          ),
        meta: { permissions: ["stores-show"] }
      }
    ]
  }
];
